type TTupleToMirrorObject<TTuple extends readonly string[]> = {
    [TKey in TTuple[number]]: TKey;
};

type TEnumerationValue<TObject extends Record<string, string | number>> = TObject[keyof TObject];

const isValuesList = (valuesOrKeysAndValues: readonly string[] | unknown): valuesOrKeysAndValues is readonly string[] =>
    Array.isArray(valuesOrKeysAndValues);

function enumeration<TTuple extends readonly string[]>(values: [...TTuple]): TTupleToMirrorObject<TTuple>;
function enumeration<TObject extends {[TKey in string]: TValue}, TValue extends string | number>(
    keysAndValues: TObject
): TObject;

/**
 * Provides a DX friendly API to create "enum-like" objects which avoid many of the pitfalls
 * associated with TypeScript's native `enum`.
 *
 * The decision and reasons for removing Typescript enums from the code is documented here:
 *
 * @see https://github.com/getndazn/peng-html5-core/issues/55
 *
 * Two variations are provided where either:
 * - the keys and values are strings and identical (mirrored)
 * - the keys and values may differ (when CONSTANT_CASE values are not desired).
 *
 * In the first case, an array of all keys may be passed (which will also become the values). In the
 * second case, an object of arbitrary keys and values may be passed.
 *
 * @example Mirrored keys and values (passing an array)
 * ```
 * const MyEnum = enumeration(['FOO', 'BAR', 'BAZ']);
 * ```
 *
 * @example Free keys and values (passing an object)
 * ```
 * const MyEnum = enumeration({FOO: 'foo', BAR: 'bar', BAZ: 'baz'});
 * ```
 */

function enumeration<TType>(valuesOrKeysAndValues: TType) {
    if (isValuesList(valuesOrKeysAndValues)) {
        const obj: Record<string, string | number> = {};

        for (const element of valuesOrKeysAndValues) {
            obj[element] = element;
        }

        return obj;
    }

    return valuesOrKeysAndValues;
}

export type {TEnumerationValue};
export {enumeration};
