import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import type {LifecycleRequestType} from './LifecycleRequestType';
import {PublicApiMessageId} from './PublicApiMessageId';

const publicApiMessages = {
    [MessageType.LOG]: {
        [PublicApiMessageId._000_DISPATCHING_EVENT]: (eventType: string): string => `Dispatching event "${eventType}"`,
        [PublicApiMessageId._001_MANIFEST_LOAD_REQUESTED]: (
            url: string,
            startTimeSeconds: number | undefined
        ): string =>
            `Requesting load of manifest "${url}"` +
            (typeof startTimeSeconds === 'number' ? ` with start time ${startTimeSeconds}s` : ''),
        [PublicApiMessageId._002_MANIFEST_REPLACEMENT_REQUESTED]: (url: string): string =>
            `Requesting manifest replacement of "${url}"`,
        [PublicApiMessageId._003_MANIFEST_UNLOAD_REQUESTED]: (): string => 'Requesting manifest unload',
        [PublicApiMessageId._004_PLAYER_CREATED]: (version: string, profile: string): string =>
            `Player created using Mercury v${version} and build profile "${profile}"`,
        [PublicApiMessageId._005_PLAYER_DESTROYED]: (): string => 'Player destroyed',
        [PublicApiMessageId._012_LOAD_REQUEST_PENDING_WHILE_UNLOADING]: (nextUrl: string, prevUrl: string): string =>
            `Load request for manifest "${nextUrl}" pending while current manifest "${prevUrl}" is unloaded`,
        [PublicApiMessageId._013_MANIFEST_LOAD_IN_PROGRESS]: (url: string): string =>
            `Player unloaded and idle, beginning load of manifest "${url}"`,
        [PublicApiMessageId._014_MANIFEST_REPLACE_IN_PROGRESS]: (url: string): string =>
            `Initiating manifest replacement with manifest "${url}"`,
        [PublicApiMessageId._016_TEAR_DOWN_REQUEST_PENDING_WHILE_KEY_SESSION_PENDING]: (
            requestType: LifecycleRequestType
        ): string =>
            `A tear-down was requested in response to a lifecycle request of type "${requestType}", but a ` +
            'DRM key session is pending. Tear down will commence once the key session is active',
        [PublicApiMessageId._017_TEAR_DOWN_REQUEST_PROCEEDING_AFTER_TIMEOUT]: (
            requestType: LifecycleRequestType | string
        ): string =>
            'A pending DRM key session which prevented tear down in response to a lifecycle request ' +
            `of ${requestType} did not fulfil after the specified timeout duration. Proceeding with tear down.`,
        [PublicApiMessageId._018_LANGUAGE_CHANGED]: (language: string): string =>
            `Language preference set to "${language}"`,
    },
    [MessageType.WARNING]: {
        [PublicApiMessageId._007_INVALID_EVENT_LISTENER_ADDED]: (eventType: string): string =>
            `"${eventType}" is not a valid EventType, see src/Core/Player/Constants/EventType.ts`,
    },
    [MessageType.ERROR]: {
        [PublicApiMessageId._006_INVALID_CONSUMER_VALUE]: (message: string): string => message,
        [PublicApiMessageId._008_UNEXPECTED_API_CALL_PLAYER_DESTROYED]: (apiName: string): string =>
            `"${apiName}" cannot be called after the Player has been destroyed`,
        [PublicApiMessageId._009_INVALID_MANIFEST_URL]: (manifestUrl: unknown): string =>
            `Invalid manifest URL "${manifestUrl}"`,
        [PublicApiMessageId._010_INVALID_REPLACE_REQUEST]: (): string =>
            'Cannot replace a manifest when no manifest is loaded',
        [PublicApiMessageId._011_WORKER_ERROR]: (url: string, error: unknown): string =>
            `WebWorker error for worker script "${url}": ${error instanceof Error ? error.message : error}`,
        [PublicApiMessageId._015_VIDEO_ELEMENT_IN_USE]: (): string =>
            'The provided video element is already bound to another mercury instance',
    },
};

export type TPublicApiMessages = TLoggerMessages<MessageNamespace._012_PUBLIC_API, typeof publicApiMessages>;

export {publicApiMessages};
