import type {TKeySessionStatusMachineEventType} from '@DrmController/StateMachines/keySessionStatusMachine';
import type {TKeySessionTypeStateMachineEvent} from '@DrmController/StateMachines/keySessionTypeMachine';
import type {TKeySessionType} from '@DrmController/Types/TKeySessionType';
import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import type {DrmKeySystemId} from '@Shared/Constants/DrmKeySystemId';

import {DrmControllerMessageId} from './DrmControllerMessageId';
import type {KeySessionStatus} from './KeySessionStatus';

const drmControllerMessages = {
    [MessageType.LOG]: {
        [DrmControllerMessageId._000_KEY_SYSTEM_ACCESS_PERMITTED]: (
            keySystemId: string,
            wasPersistentAccessGranted: boolean
        ): string =>
            `DRM key system access permitted for system "${keySystemId}" with ${
                wasPersistentAccessGranted ? 'persistent' : 'temporary'
            } key system access`,
        [DrmControllerMessageId._001_KEY_SYSTEM_ACCESS_DENIED]: (keySystemId: string): string =>
            `DRM key system access denied for system "${keySystemId}"`,
        [DrmControllerMessageId._002_REQUESTING_KEY_SESSION_FROM_MANIFEST_DATA]: (): string =>
            'Requesting new key session based on PSSH data in manifest',
        [DrmControllerMessageId._003_REQUESTING_KEY_SESSION_FROM_INIT_DATA]: (): string =>
            'Requesting new key session based on `initData` found in newly appended encrypted segment',
        [DrmControllerMessageId._004_EXISTING_KEY_SESSION_FOUND]: (keySessionStatus: KeySessionStatus): string =>
            `Existing ${keySessionStatus} key session found. No new key session will be created.`,
        [DrmControllerMessageId._005_REUSING_CLOSED_KEY_SESSION]: (): string =>
            'Reusing previously closed key session with matching `initData` and persistent license',
        [DrmControllerMessageId._006_KEY_SESSION_ACTIVE]: (
            sessionId: string,
            timeRemaining: string,
            expiration: string
        ): string => {
            return `Key session "${sessionId}" authenticated successfully${
                expiration ? `. License will expire in ${timeRemaining} hours at ${expiration}.` : ''
            }`;
        },
        [DrmControllerMessageId._008_REQUESTING_LICENSE]: (keySystemId: string): string =>
            `Requesting license for key system ID "${keySystemId}"`,
        [DrmControllerMessageId._009_TRANSFORMING_LICENSE_REQUEST]: (): string =>
            'Applying consumer-provided `transformLicenseRequest` function',
        [DrmControllerMessageId._010_TRANSFORMING_LICENSE_RESPONSE]: (): string =>
            'Applying consumer-provided `transformLicenseResponse` function',
        [DrmControllerMessageId._016_CREATING_NEW_KEY_SESSION]: (sessionType: TKeySessionType): string =>
            `Creating new key session with session type "${sessionType}"`,
        [DrmControllerMessageId._018_SESSION_REQUESTING_LICENSE]: (isCertificateRequest: boolean): string =>
            `\`license-request\` message received from session of type "${
                isCertificateRequest ? 'certificate' : 'license'
            }"`,
        [DrmControllerMessageId._019_LICENSE_APPLIED_TO_SESSION]: (): string => 'Updating session with cached license',
        [DrmControllerMessageId._020_LICENSE_SERVER_RESPONSE_RECEIVED]: (): string =>
            'License response received from license server',
        [DrmControllerMessageId._024_PLAYREADY_CHALLENGE_XML_DETECTED]: (): string =>
            'XML-formatted PlayReady challenge message detected',
        [DrmControllerMessageId._025_PLAYREADY_CHALLENGE_RAW_DETECTED]: (): string =>
            'Raw array buffer PlayReady challenge message detected',
        [DrmControllerMessageId._030_DEVICE_LICENSE_CACHE_FLUSHED]: (): string => 'Device DRM license cache flushed',
        [DrmControllerMessageId._031_FAILING_OVER_FROM_ERRORED_KEY_SESSION]: (): string =>
            // eslint-disable-next-line max-len
            'An existing key session was found for the provided `initData` but had an ERRORED state. Trying a previously CLOSED session instead.',
        [DrmControllerMessageId._032_KEY_SESSION_CLOSED]: (sessionId: string): string =>
            `Key session "${sessionId}" closed`,
        [DrmControllerMessageId._044_ATTEMPTING_DIRECT_LICENSE_REUSE]: () =>
            'Attempting direct re-use of in-memory cached PlayReady license',
        [DrmControllerMessageId._045_USING_PLAYREADY_RECOMMENDATION_KEY_SYSTEM_ID]: (
            rejectedKeySystemId: string,
            permittedKeySystemId: string
        ): string =>
            `Persistent access was rejected with key system ID "${rejectedKeySystemId}", using ` +
            `"${permittedKeySystemId}" instead`,
        [DrmControllerMessageId._046_MATCHING_KEY_ID_NON_MATCHING_INIT_DATA]: (keyId: string) =>
            `A previous key session was found with a matching key ID "${keyId}" but differing init ` +
            'data. Its init data shall be overwritten to match incoming init data.',
        [DrmControllerMessageId._047_KEY_SESSION_REUSED]: (
            sessionId: string,
            timeRemaining: string,
            expiration: string
        ): string =>
            `Key session "${sessionId}" reused successfully${
                expiration ? `. License will expire in ${timeRemaining} hours at ${expiration}.` : ''
            }`,
        [DrmControllerMessageId._048_KEY_SESSION_STATUS_CHANGE]: (
            sessionId: string,
            prevStatus: KeySessionStatus,
            nextStatus: KeySessionStatus,
            eventType: TKeySessionStatusMachineEventType
        ): string =>
            `Key session "${sessionId}" status changed from "${prevStatus}" to "${nextStatus}" due ` +
            `to event type "${eventType}"`,
        [DrmControllerMessageId._049_KEY_SESSION_TYPE_CHANGE]: (
            sessionId: string,
            prevSessionType: TKeySessionType,
            nextSessionType: TKeySessionType,
            eventType: TKeySessionTypeStateMachineEvent
        ): string =>
            `Key session "${sessionId}" type changed from "${prevSessionType}" to "${nextSessionType}" due ` +
            `to event type "${eventType}"`,
        [DrmControllerMessageId._050_PERSISTING_NATIVE_KEY_SESSION_FOR_FUTURE_USE]: (sessionId: string) =>
            `Key session "${sessionId}" will be persisted in application memory for future use.`,
    },
    [MessageType.WARNING]: {
        [DrmControllerMessageId._007_KEY_SESSION_ERROR]: (messageOrException: unknown): string =>
            `Key session error: ${messageOrException}`,
        [DrmControllerMessageId._026_FAILED_TO_REQUEST_SESSION_FROM_INIT_DATA]: (keySystemId: DrmKeySystemId): string =>
            // eslint-disable-next-line max-len
            `Failed to request new key session using \`initData\` from newly appended segment, with key system "${keySystemId}"`,
        [DrmControllerMessageId._027_FAILED_TO_REQUEST_SESSION_FROM_MANIFEST_DATA]: (
            keySystemId: DrmKeySystemId
        ): string =>
            `Failed to request new key session using PSSH data from manifest, with key system "${keySystemId}"`,
        [DrmControllerMessageId._029_KEY_EVENT_DISPATCHED_WITH_NO_CHALLENGE]: (sessionId: string): string =>
            `Key event dispatched by session "${sessionId}" with no challenge data`,
        [DrmControllerMessageId._034_LICENSE_URL_NOT_SET_FOR_SUPPORTED_KEY_SYSTEM]: (
            keySystemId: DrmKeySystemId
        ): string =>
            `Key system "${keySystemId}" supported by browser and present in content but no ` +
            '`licenseAcquisitionUrl` set',
        [DrmControllerMessageId._035_COULD_NOT_INITIALISE_MEDIA_KEYS]: (messageOrException: unknown): string =>
            `Could not initialise \`MediaKeys\` on video element: ${messageOrException}`,
        [DrmControllerMessageId._037_SYNTHESISING_PLAYREADY_PSSH_LA_URL]: (_psshXml: string): string =>
            'The PlayReady PSSH data found in the manifest did not contain a `<LA_URL>` element. ' +
            'It will be synthesised',
        [DrmControllerMessageId._038_FAILED_TO_REQUEST_SESSION_OF_TYPE_PERSISTENT_LICENSE]: (
            messageOrException: unknown
        ): string =>
            `Could not create session with session type "persistent-license": ${messageOrException}. ` +
            'Retrying with session type "temporary"',
        [DrmControllerMessageId._039_INIT_DATA_MISMATCH_BETWEEN_SOURCES]: (): string =>
            'The manifest and init segments for this content have non-matching init data which may ' +
            'indicate a packaging issue. A key session will be created for the first valid source ' +
            'only. All other requests with non-matching init data will marked as ERRORED to avoid usage.',
        [DrmControllerMessageId._040_PERSISTENT_LICENSE_SESSION_TYPE_NOT_PERMITTED]: (
            keySessionType: TKeySessionType
        ): string =>
            'A persistent session was requested due to build profile configuration, ' +
            `but the CDM configuration does not permit it. Falling back to type "${keySessionType}".`,
        [DrmControllerMessageId._041_KEY_SYSTEM_ACCESS_REJECTED_WITH_PERSISTENT_PRIVILEGES]: (
            keySystemId: DrmKeySystemId
        ): string =>
            `Media key system access for "${keySystemId}" was requested for persistent licence ` +
            'privileges but the request was rejected. Retrying without privileges',
        [DrmControllerMessageId._042_PERSISTENT_LICENSE_REUSE_ATTEMPT_FAILED]: (messageOrException: unknown): string =>
            'An attempt to re-use a previous key session with a persistent license failed with ' +
            `"${messageOrException}". Falling back to new license request.`,
        [DrmControllerMessageId._043_PERSISTENT_LICENSE_MISSING_EXPIRY]: (
            humanReadableDefaultPersistentLicenseDuration: string
        ): string =>
            'A persistent key session was successfully authenticated, but no expiration was ' +
            `provided. A default duration of ${humanReadableDefaultPersistentLicenseDuration} ` +
            'hours shall be used.',
        [DrmControllerMessageId._051_MAX_KEY_SESSIONS_LIMIT_EXCEEDED]: (
            exceededKeySessionsCount: number,
            maxKeySessions: number
        ): string =>
            `The maximum configured number of key sessions (${maxKeySessions}) has been exceeded ` +
            `by ${exceededKeySessionsCount}. ${exceededKeySessionsCount} key sessions will now be ` +
            'closed and discarded.',
    },
    [MessageType.ERROR]: {
        [DrmControllerMessageId._011_NO_KEY_SYSTEM_AVAILABLE]: (): string =>
            'CDM unavailable or no compatible or configured key system access available for provided content',
        [DrmControllerMessageId._012_UNEXPECTED_SESSION_MESSAGE]: (messageType: string): string =>
            `Unexpected session message type: "${messageType}"`,
        [DrmControllerMessageId._013_NO_MATCHING_SESSION_FOUND]: (): string => 'No matching key session found',
        [DrmControllerMessageId._014_EMPTY_LICENSE_RESPONSE]: (): string =>
            'License server returned empty license response',
        [DrmControllerMessageId._015_INVALID_CENC_PSSH]: (): string => 'Missing CENC PSSH data',
        [DrmControllerMessageId._017_MEDIA_KEYS_NOT_PRESENT]: (): string =>
            'No `MediaKeys` instance present on video element',
        [DrmControllerMessageId._021_NO_LICENSE_ACQUISITION_URL_SET]: (keySystemId: string): string =>
            `No \`licenseAcquisitionUrl\` configured for key system "${keySystemId}"`,
        [DrmControllerMessageId._022_UNEXPECTED_PLAYREADY_CHALLENGE_ENCODING]: (encoding: string): string =>
            `Unexpected encoding "${encoding}" found in PlayReady challenge XML`,
        [DrmControllerMessageId._023_NO_PLAYREADY_CHALLENGE_ELEMENT_FOUND_IN_XML]: (): string =>
            '`<Challenge>` element not found in PlayReady challenge XML',
        [DrmControllerMessageId._028_NO_DRM_SUPPORT]: (): string =>
            'No DRM support present in browser. Ensure you are running the application in a secure environment.',
        [DrmControllerMessageId._033_METHOD_NOT_IMPLEMENTED]: (methodName: string): string =>
            `Method "${methodName}" not implemented`,
        [DrmControllerMessageId._036_CDM_NOT_CLOSED]: (): string =>
            "The video element's `MediaKeys` is not `null` after closing",
    },
};

export type TDrmControllerMessages = TLoggerMessages<
    MessageNamespace._019_DRM_CONTROLLER,
    typeof drmControllerMessages
>;

export {drmControllerMessages};
