import {FeatureBandwidthEstimationMessageId} from '@Features/BandwidthEstimation/Constants/FeatureBandwidthEstimationMessageId';
import {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {logger} from '@Logger/logger';
import type {IDownloaderEndOfDownloadMetricsEvent} from '@Network/Types/TDownloaderEvent';
import {AssetType} from '@Shared/Constants/AssetType';

import type {Metrics} from '../Models/Metrics';
import {updateEwmaValue} from '../Util/updateEwmaValue';

type TNetworkActions = {
    addThroughputBasedBandwidthSample(bandwidthSample: number): void;
    addEndOfFileMetrics(endOfDownloadMetrics: IDownloaderEndOfDownloadMetricsEvent): void;
    setActiveVariantIndex(activeVariantIndex: number): void;
    reseedEwmaFromThroughputBasedBandwidthEstimate(): void;
};

const resolveNetworkActions = ({network}: Metrics): TNetworkActions => ({
    addThroughputBasedBandwidthSample(bandwidthSample) {
        network.throughputBasedBandwidthSamples.push(bandwidthSample);

        if (network.throughputBasedBandwidthSamples.length > network.configAbr.maxRollingAverageSamples) {
            network.throughputBasedBandwidthSamples.shift();
        }

        logger.log(
            MessageNamespace._003_FEATURE_BANDWIDTH_ESTIMATION,
            FeatureBandwidthEstimationMessageId._001_THROUGHPUT_BASED_ESTIMATE_CHANGED,
            network.humanReadableThroughputBasedBandwidthEstimate
        );
    },

    addEndOfFileMetrics({assetType, contentDownloadDurationMs, requestLatencyMs, throughputMultiplier, totalBytes}) {
        network.requestLatencySamples.push(requestLatencyMs);

        if (network.requestLatencySamples.length > network.configAbr.maxRollingAverageSamples) {
            network.requestLatencySamples.shift();
        }

        logger.log(
            MessageNamespace._003_FEATURE_BANDWIDTH_ESTIMATION,
            FeatureBandwidthEstimationMessageId._002_LATENCY_ESTIMATE_CHANGED,
            `${network.requestLatencyEstimate}ms`
        );

        if (assetType === AssetType.VIDEO_SEGMENT) {
            const contentDownloadDurationSeconds = contentDownloadDurationMs / 1000;

            const bandwidth = ((totalBytes * 8) / contentDownloadDurationSeconds) * throughputMultiplier;

            network.timeBasedBandwidthEwmaFast = updateEwmaValue(
                network.timeBasedBandwidthEwmaFast,
                bandwidth,
                network.configAbr.ewmaHalfLifeFast
            );

            network.timeBasedBandwidthEwmaSlow = updateEwmaValue(
                network.timeBasedBandwidthEwmaSlow,
                bandwidth,
                network.configAbr.ewmaHalfLifeSlow
            );

            logger.log(
                MessageNamespace._003_FEATURE_BANDWIDTH_ESTIMATION,
                FeatureBandwidthEstimationMessageId._003_TIME_BASED_ESTIMATE_CHANGED,
                network.humanReadableTimeBasedBandwidthEstimate
            );
        }
    },

    setActiveVariantIndex(activeVariantIndex) {
        network.activeVariantIndex = activeVariantIndex;
    },

    reseedEwmaFromThroughputBasedBandwidthEstimate() {
        if (isNaN(network.throughputBasedBandwidthEstimate)) {
            logger.warn(
                MessageNamespace._003_FEATURE_BANDWIDTH_ESTIMATION,
                FeatureBandwidthEstimationMessageId._005_RESEEDING_PREVENTED
            );

            return;
        }

        network.timeBasedBandwidthEwmaFast = network.throughputBasedBandwidthEstimate;
        network.timeBasedBandwidthEwmaSlow = network.throughputBasedBandwidthEstimate;
    },
});

export type {TNetworkActions};
export {resolveNetworkActions};
