import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import type {ManifestType} from '@Shared/Constants/ManifestType';
import type {Period} from '@Shared/Models/Period';

import {FeatureManifestLoadingMessageId} from './FeatureManifestLoadingMessageId';

const featureManifestLoadingMessages = {
    [MessageType.LOG]: {
        [FeatureManifestLoadingMessageId._001_DASH_DYNAMIC_MANIFEST_REPLACED]: (): string =>
            'Dynamic DASH manifest replaced',
        [FeatureManifestLoadingMessageId._000_DASH_DYNAMIC_MANIFEST_UPDATED]: (): string =>
            'Dynamic DASH manifest updated',
        [FeatureManifestLoadingMessageId._002_DASH_STATIC_MANIFEST_REPLACED]: (): string =>
            'Static DASH manifest replaced',
        [FeatureManifestLoadingMessageId._003_DASH_MANIFEST_PARSED]: (type: ManifestType): string =>
            `DASH manifest parsed of type "${type}"`,
        [FeatureManifestLoadingMessageId._004_HLS_MASTER_MANIFEST_PARSED]: (): string => 'HLS master manifest parsed',
        [FeatureManifestLoadingMessageId._006_HLS_MEDIA_MANIFEST_AUDIO_PARSED_OF_VARIANT_INDEX]: (
            variantIndex: number
        ): string => `HLS media manifest for audio variant ${variantIndex} parsed`,
        [FeatureManifestLoadingMessageId._005_HLS_MEDIA_MANIFEST_VIDEO_PARSED_OF_VARIANT_INDEX]: (
            variantIndex: number
        ): string => `HLS media manifest for video variant ${variantIndex} parsed`,
        [FeatureManifestLoadingMessageId._007_LIVE_START_DELAY_ADDED]: (delayMs: number): string =>
            `Delaying start of live playback by ${delayMs}ms to ensure sufficient buffer ahead duration`,
        [FeatureManifestLoadingMessageId._008_PRESENTATION_TIMELINE_SHIFTED]: (timelineShiftSeconds: number): string =>
            `Presentation timeline shifted by ${timelineShiftSeconds} seconds due to high presentation timestamps`,
        [FeatureManifestLoadingMessageId._010_DYNAMIC_MANIFEST_SYNC_INTERVAL_SET]: (
            syncIntervalSeconds: number
        ): string => `Dynamic manifest will be synced every ${syncIntervalSeconds} seconds`,
    },
    [MessageType.WARNING]: {
        [FeatureManifestLoadingMessageId._011_JOINING_AT_PREFERRED_PERIOD_START]: (period: Period): string =>
            `Preferred period "${period.id}" found, seeking to ${period.startTimeSeconds}s`,
    },
    [MessageType.ERROR]: {
        [FeatureManifestLoadingMessageId._009_UNKNOWN_ABR_FORMAT]: (url: string): string =>
            `The streaming protocol could not be automatically inferred from the URL "${url}"`,
    },
};

export type TFeatureManifestLoadingMessages = TLoggerMessages<
    MessageNamespace._006_FEATURE_MANIFEST_LOADING,
    typeof featureManifestLoadingMessages
>;

export {featureManifestLoadingMessages};
