import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {TransmuxerMessageId} from './TransmuxerMessageId';

const transmuxerMessages = {
    [MessageType.LOG]: {
        [TransmuxerMessageId._000_PREPENDING_INIT_SEGMENT]: (): string =>
            'Prepending init segment data while transmuxing as first segment of new variant or period',
        [TransmuxerMessageId._001_TRANSMUXED_MP2_TO_MP4]: (): string => 'Transmuxing from MP2 to MP4 successful',
    },
    [MessageType.WARNING]: {},
    [MessageType.ERROR]: {},
};

export type TTransmuxerMessages = TLoggerMessages<MessageNamespace._017_TRANSMUXER, typeof transmuxerMessages>;

export {transmuxerMessages};
