import {SegmentStatus} from '@Shared/Constants/SegmentStatus';
import type {Segment} from '@Shared/Models/Segment';

import {ConfigAbr} from '../../Config/Models/ConfigAbr';
import {getEndOfRange} from '../Util/getEndOfRange';
import {getTimeSortedBufferRanges} from '../Util/getTimeSortedBufferRanges';

class BufferData {
    public readonly configAbr: ConfigAbr = new ConfigAbr();
    public bufferedRangeMap: Record<string, Segment> = {};
    public bufferingRangeMap: Record<string, Segment> = {};
    public initSegmentRequired = false;
    public previousBufferingSegment: Segment | null = null;

    public get sortedBufferedRanges() {
        return getTimeSortedBufferRanges(this.bufferedRangeMap);
    }

    public get sortedBufferedRangeKeys(): string[] {
        return this.sortedBufferedRanges.map(segment => segment.id);
    }

    public get bufferedStartTimeSeconds(): number {
        const [firstBufferedRange] = this.sortedBufferedRanges;

        return firstBufferedRange ? firstBufferedRange.startTimeSeconds : 0;
    }

    public get bufferedEndTimeSeconds(): number {
        return getEndOfRange(this.bufferedRangeMap, this.configAbr.smallGapThresholdSeconds);
    }

    public get bufferedSegmentsCount(): number {
        return Object.keys(this.bufferedRangeMap).length;
    }

    public get sortedBufferingRanges() {
        return getTimeSortedBufferRanges(this.bufferingRangeMap);
    }

    public get sortedBufferingRangeKeys(): string[] {
        return this.sortedBufferingRanges.map(segment => segment.id);
    }

    public get bufferingStartTimeSeconds(): number {
        const [firstBufferingRange] = this.sortedBufferingRanges;

        return firstBufferingRange ? firstBufferingRange.startTimeSeconds : 0;
    }

    public get bufferingEndTimeSeconds(): number {
        return getEndOfRange(this.bufferingRangeMap, this.configAbr.smallGapThresholdSeconds);
    }

    public get bufferingSegmentsCount(): number {
        return Object.keys(this.bufferingRangeMap).length;
    }

    public get downloadingBufferingSegmentsCount(): number {
        return Object.keys(this.bufferingRangeMap)
            .map<Segment>(key => this.bufferingRangeMap[key])
            .filter(
                segment =>
                    segment.status !== SegmentStatus.AWAITING_APPEND && segment.status !== SegmentStatus.APPENDING
            ).length;
    }
}

export {BufferData};
