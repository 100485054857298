import {StalledStatus} from '../Constants/StalledStatus';
import type {Playback} from '../Models/Playback';
import type {Timing} from '../Models/Timing';

const stalledStatusMachine = (
    prevStalledStatus: StalledStatus,
    playback: Playback,
    timing: Timing,
    didRecover: boolean
): StalledStatus => {
    const nextStalledStatus = deriveNextStalledStatusFromMetrics(playback, timing, didRecover);

    switch (prevStalledStatus) {
        // When in a `NOT_STALLED` status, any next status is allowed
        case StalledStatus.NOT_STALLED:
            switch (nextStalledStatus) {
                default:
                    return nextStalledStatus;
            }

        // Once stalled "unexpectedly", we can move only to a `NOT_STALLED` status (recovery).
        case StalledStatus.STALLED_CAUSE_UNKNOWN:
            switch (nextStalledStatus) {
                case StalledStatus.NOT_STALLED:
                    return nextStalledStatus;
                default:
                    return prevStalledStatus;
            }

        // For all other "expected stalls", we can move to an "unexpected stall", or recover
        default:
            return nextStalledStatus;
    }
};

const deriveNextStalledStatusFromMetrics = (playback: Playback, timing: Timing, didRecover: boolean): StalledStatus => {
    if (didRecover) {
        return StalledStatus.NOT_STALLED;
    } else if (playback.isSeeking) {
        // NB: It may be possible to detect seeking with the following `!timing.isInsideBuffer` condition,
        // but if buffer withholding is active, the buffer may appear full, while the native buffer
        // has not yet been appended, so we check the seek status first

        return StalledStatus.STALLED_DURING_SEEK;
    } else if (playback.isKeySessionPending) {
        return StalledStatus.STALLED_AWAITING_KEY_SESSION;
    } else if (!timing.isInsideBuffer) {
        return StalledStatus.STALLED_AT_END_OF_BUFFER;
    } else if (playback.playbackRate === 0 && playback.configBehaviourAllowZeroPlaybackRate) {
        return StalledStatus.STALLED_PLAYBACK_RATE_ZERO;
    } else if (timing.isInsideGap) {
        return StalledStatus.STALLED_AT_GAP;
    } else if (timing.isAtOrBeyondEndOfStream) {
        // NB: in the case we stall just before a native `ended` if achieved
        return StalledStatus.STALLED_AT_END;
    } else if (!playback.hasDecodedFrames) {
        return StalledStatus.STALLED_AWAITING_DECODED_FRAMES;
    }

    // All known reasons for a stall have been checked, assume unknown hardware glitch

    return StalledStatus.STALLED_CAUSE_UNKNOWN;
};

export {stalledStatusMachine};
