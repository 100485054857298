import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {GenericMessageId} from './GenericMessageId';
import type {StallRecoveryStrategy} from './StallRecoveryStrategy';

const genericMessages = {
    [MessageType.LOG]: {},
    [MessageType.WARNING]: {
        [GenericMessageId._002_UNEXPECTED_STALL]: (strategies: StallRecoveryStrategy[]): string =>
            `The player stalled unexpectedly after exhausting recovery strategies: ${strategies.join(', ')}`,
    },
    [MessageType.ERROR]: {
        [GenericMessageId._000_UNHANDLED_EXCEPTION]: (err?: Error | string | number | unknown): string =>
            err ? `Unhandled exception: ${err instanceof Error ? err.message : err}` : 'Unhandled exception',
        [GenericMessageId._001_WORKER_NOT_PRESENT]: (): string => 'WebWorker expected but not present in build',
    },
};

export type TGenericMessages = TLoggerMessages<MessageNamespace._009_GENERIC, typeof genericMessages>;

export {genericMessages};
