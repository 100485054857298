import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import type {IInvalidMtaBandwidthLadderElement} from '@ManifestParsers/Dash/Interfaces/IInvalidMtaBandwidthLadderElement';
import type {IMissingLanguageAudioRepresentationElement} from '@ManifestParsers/Dash/Interfaces/IMissingLanguageAudioRepresentationElement';
import {hasElements} from '@Shared/Util/hasElements';

import {DashManifestParserMessageId} from './DashManifestParserMessageId';

const dashManifestParserMessages = {
    [MessageType.LOG]: {},
    [MessageType.WARNING]: {
        [DashManifestParserMessageId._006_MISSING_AUDIO_SEGMENT_DEFINITION]: (representationId?: string): string =>
            `No segment data found for audio representation${representationId ? ` "${representationId}"` : ''}`,
        [DashManifestParserMessageId._023_MISSING_VIDEO_SEGMENT_DEFINITION]: (representationId?: string): string =>
            `No segment data found for video representation${representationId ? ` "${representationId}"` : ''}`,
        [DashManifestParserMessageId._031_MISSING_LANGUAGE_FOR_TEXT_TRACK]: (): string =>
            '`lang` attribute missing for `<AdaptationSet>` element of type `text`',
        [DashManifestParserMessageId._032_MISSING_AUDIO_REPRESENTATION_ELEMENT_FOR_LANGUAGE]: (
            data: IMissingLanguageAudioRepresentationElement
        ): string => `\`<AdaptationSet>\` for language "${data.language}" contains no \`<Representation>\` element`,
        [DashManifestParserMessageId._033_AUDIO_REPRESENTATION_ELEMENT_BANDWIDTH_MISMATCH]: (
            data: IInvalidMtaBandwidthLadderElement
        ): string =>
            `Highest bitrate audio tracks for benchmark language and "${data.language}" are not equal:` +
            ` ${data.benchmarkBandwidth} vs ${data.currentBandwidth}`,
        [DashManifestParserMessageId._034_ATOB_METHOD_NOT_IMPLEMENTED]: (): string => `Method "atob" not implemented`,
    },
    [MessageType.ERROR]: {
        [DashManifestParserMessageId._000_METHOD_NOT_IMPLEMENTED]: (methodName: string): string =>
            `Method "${methodName}" not implemented`,
        [DashManifestParserMessageId._001_MISSING_ADAPTATION_SET_ELEMENT]: (): string =>
            '<Period> contains no `<AdaptationSet>` element',
        [DashManifestParserMessageId._002_MISSING_AUDIO_ADAPTATION_SET_ELEMENT]: (): string =>
            '`<Period>` contains no audio `<AdaptationSet>` element',
        [DashManifestParserMessageId._003_MISSING_AUDIO_CODEC]: (): string =>
            '`Representation@codecs` or `AdaptationSet@codecs` attribute missing for audio content',
        [DashManifestParserMessageId._004_MISSING_AUDIO_REPRESENTATION_BANDWIDTH]: (): string =>
            '`Representation@bandwidth` attribute missing for audio content',
        [DashManifestParserMessageId._005_MISSING_AUDIO_REPRESENTATION_ELEMENT]: (): string =>
            '`<AdaptationSet>` contains no `<Representation>` element for audio content',
        [DashManifestParserMessageId._007_MISSING_AVAILABILITY_START_TIME]: (): string =>
            '`MPD@availabilityStartTime` attribute missing',
        [DashManifestParserMessageId._008_MISSING_MANIFEST_DATA]: (): string => 'No XML data received',
        [DashManifestParserMessageId._009_MISSING_MANIFEST_TYPE]: (): string => '`MPD@type` attribute missing',
        [DashManifestParserMessageId._010_MISSING_MPD_ELEMENT]: (): string =>
            'Could not find `<MPD>` element in provided XML',
        [DashManifestParserMessageId._011_MISSING_PERIOD_ELEMENT]: (availableNodes: string[]): string =>
            `\`<MPD>\` element contains no \`<Period>\` element${
                hasElements(availableNodes) ? ` but found: ${availableNodes.map(node => `\`${node}\``).join(', ')}` : ''
            }`,
        [DashManifestParserMessageId._012_MISSING_PERIOD_START]: (): string => '`Period@start` attribute missing',
        [DashManifestParserMessageId._013_MISSING_SEGMENT_TEMPLATE_INITIALIZATION_SEGMENT_URI]: (): string =>
            '`SegmentTemplate@initialization` attribute missing',
        [DashManifestParserMessageId._014_MISSING_SEGMENT_TEMPLATE_MEDIA_URI]: (): string =>
            '`SegmentTemplate@media` attribute missing',
        [DashManifestParserMessageId._015_MISSING_SEGMENT_TEMPLATE_TIMESCALE]: (): string =>
            '`SegmentTemplate@timescale` attribute missing',
        [DashManifestParserMessageId._016_MISSING_SEGMENT_TIMELINE_ELEMENT]: (): string =>
            '`<SegmentTemplate>` element contains no `<SegmentTimeline>` element',
        [DashManifestParserMessageId._017_MISSING_SEGMENT_TIMELINE_S_ELEMENT]: (): string =>
            '`<SegmentTimeline>` element contains no `<S>` element',
        [DashManifestParserMessageId._018_MISSING_SEGMENT_TIMELINE_SEGMENT_DURATION]: (): string =>
            '`S@d` attribute missing',
        [DashManifestParserMessageId._019_MISSING_VIDEO_ADAPTATION_SET_ELEMENT]: (): string =>
            '`<Period>` contains no video `<AdaptationSet>` element',
        [DashManifestParserMessageId._020_MISSING_VIDEO_CODEC]: (): string =>
            '`Representation@codecs` or `AdaptationSet@codecs` attribute missing for video content',
        [DashManifestParserMessageId._021_MISSING_VIDEO_REPRESENTATION_BANDWIDTH]: (): string =>
            '`Representation@bandwidth` attribute missing for video content',
        [DashManifestParserMessageId._022_MISSING_VIDEO_REPRESENTATION_ELEMENT]: (): string =>
            '`<AdaptationSet>` contains no `<Representation>` element for video content',
        [DashManifestParserMessageId._024_UNHANDLED_EXCEPTION]: (err: unknown): string =>
            `Unhandled exception: ${err instanceof Error ? err.message : err}`,
        [DashManifestParserMessageId._025_INVALID_ISO8601_DURATION]: (value: string): string =>
            `Invalid ISO 8601 duration "${value}"`,
        [DashManifestParserMessageId._026_AMBIGUOUS_ISO8601_DURATION]: (value: string): string =>
            // eslint-disable-next-line max-len
            `Ambiguous ISO 8601 duration "${value}". Values in years (Y) or months (M) are date dependent and cannot be accurately parsed into seconds.`,
        [DashManifestParserMessageId._027_MISSING_EVENT_DATA]: (): string =>
            '`Event@messageData` attribute missing and no text content found',
        [DashManifestParserMessageId._028_MISSING_EVENT_PRESENTATION_TIME]: (): string =>
            '`Event@presentationTime` attribute missing',
        [DashManifestParserMessageId._029_MISSING_EVENT_STREAM_TIMESCALE]: (): string =>
            '`EventStream@timescale` attribute missing',
        [DashManifestParserMessageId._030_MISSING_DURATION_FOR_SHORTHAND_SEGMENT_TEMPLATE]: (): string =>
            '`A shared, shorthand <SegmentTemplate> was found, but the `Period@duration` and the ' +
            '`MPD@mediaPresentationDuration` attributes are both missing. The timeline is non-finite.',
    },
};

export type TDashManifestParserMessages = TLoggerMessages<
    MessageNamespace._000_DASH_MANIFEST_PARSER,
    typeof dashManifestParserMessages
>;

export {dashManifestParserMessages};
