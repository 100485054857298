import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeaturePlaybackQualityMessageId} from './FeaturePlaybackQualityMessageId';

const createPlaybackQualityReportMessage =
    (qualifier: string) =>
    (
        droppedVideoFrames: number,
        totalVideoFrames: number,
        videoFrameFailureRate: number,
        droppedVideoFramesAccelerationThreshold: number,
        droppedVideoFramesAcceleration: number,
        droppedVideoFramesSpeed: number
    ) =>
        `Playback quality is ${qualifier} \`${droppedVideoFramesAccelerationThreshold} fps\` change threshold:\n` +
        `    Dropped video frames: ${droppedVideoFrames} of ${totalVideoFrames} (${Math.round(
            videoFrameFailureRate * 100
        )}%)\n` +
        `    Rate of change:       ${Math.round(droppedVideoFramesAcceleration)} fps\n` +
        `    Speed:                ${Math.round(droppedVideoFramesSpeed)} fps`;

const featurePlaybackQualityMessages = {
    [MessageType.LOG]: {
        [FeaturePlaybackQualityMessageId._000_PLAYBACK_QUALITY_WITHIN_THRESHOLD]:
            createPlaybackQualityReportMessage('within'),
    },
    [MessageType.WARNING]: {
        [FeaturePlaybackQualityMessageId._001_PLAYBACK_QUALITY_OUTSIDE_THRESHOLD]:
            createPlaybackQualityReportMessage('outside'),
    },
    [MessageType.ERROR]: {},
};

export type TFeaturePlaybackQualityMessages = TLoggerMessages<
    MessageNamespace._027_PLAYBACK_QUALITY,
    typeof featurePlaybackQualityMessages
>;

export {featurePlaybackQualityMessages};
