import * as randomColor from 'rcolor';

import {generateMessageCode} from '@Shared/Util/generateMessageCode';
import {hasElements} from '@Shared/Util/hasElements';
import {humaniseUnixTime} from '@Shared/Util/humaniseUnixTime';
import type {createLocalStorageStore} from '@State/Stores/LocalStorage/createLocalStorageStore';

import {MessageType} from '../Constants/MessageType';
import type {TMessage} from '../Types/TMessage';

import {renderHumanReadableMessage} from './renderHumanReadableMessage';
import {renderHumanReadableMessageNamespace} from './renderHumanReadableMessageNamespace';
import {renderHumanReadableMessageTypePrefix} from './renderHumanReadableMessageTypePrefix';

/**
 * A "view" for the player's logger (Logger.ts), enabling filterable
 * output to console, colour-coded by namespace.
 */

type TRenderedMessage = {logText: string; colour: string | undefined; messageType: MessageType};

class DebugView {
    constructor(
        /**
         * An injected reference to the local storage store, so that the latest
         * logging colours and any active filters are available on demand.
         */
        private localStorageStore: ReturnType<typeof createLocalStorageStore>
    ) {}

    /**
     * Tags all information contained within a log entry and renders it into
     * human-readable format. For example:
     *
     * |-DATE/TIME-|-NAMESPACE-|-CODE-|-------MESSAGE -----|
     * HH:MM:SS.SSS [Component] [0000] Lorem ipsum dolor sit
     */

    public renderMessage(message: TMessage): TRenderedMessage | null {
        const [messageType, timestamp, namespace, messageId, args] = message;

        if (!(namespace in this.localStorageStore.value.loggingColours)) {
            // generate random colour and associate to namespace

            const newColour = randomColor({
                saturation: 1,
                // ensures readability on a white console background,
                // needs consideration for dark console
                value: 0.75,
            });

            this.localStorageStore.actions.assignLoggingColourToNamespace(namespace, newColour);
        }

        // 1. Determine colour for namespace

        const colour = this.localStorageStore.value.loggingColours[namespace];

        // 2. Parse Timestamp into human readable wallclock time, inclusive
        // of milliseconds

        const time = humaniseUnixTime(timestamp, 'timeOnly');

        // 3. Map logId and any args into human readable log string

        const humanReadableNamespace = renderHumanReadableMessageNamespace(namespace);
        const humanReadableMessageType = renderHumanReadableMessageTypePrefix(messageType);
        const humanReadableMessage = renderHumanReadableMessage(messageType, namespace, messageId, args);

        // 4. Create error code by padding and concatenating the namespace and id

        const code = generateMessageCode(namespace, messageId);

        // 5. Concatenate all segments together

        const logText = [
            `${time}`,
            `[${humanReadableNamespace}]`,
            `[${code}]`,
            humanReadableMessageType,
            humanReadableMessage,
        ]
            .filter(Boolean)
            .join(' ');

        const filters = this.localStorageStore.value.loggingFilters;

        // If no filters are present, all logs will be shown, otherwise one or more
        // filters will be evaluated by string matching, with OR logic.

        const canShow =
            !hasElements(filters) ||
            filters.reduce<boolean>((willShow, filter) => {
                if (logText.toLowerCase().indexOf(filter) > -1) return true;

                return willShow;
            }, false);

        if (!canShow) return null;

        switch (messageType) {
            case MessageType.ERROR:
                console.error(`%c${logText}`, `color:${colour}`);

                break;
            case MessageType.WARNING:
                console.warn(`%c${logText}`, `color:${colour}`);

                break;
            case MessageType.LOG:
                // eslint-disable-next-line no-console
                console.log(`%c${logText}`, `color:${colour}`);

                break;
        }

        return {logText: logText!, colour: colour!, messageType};
    }
}

export {DebugView};
