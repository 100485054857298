import type {PlaybackStatusMachineEventType} from '../Constants/PlaybackStatusMachineEventType';
import type {Metrics} from '../Models/Metrics';
import {Playback} from '../Models/Playback';
import {Timing} from '../Models/Timing';

import type {TPlaybackActions} from './resolvePlaybackActions';

type TMultiDomainActions = {
    unload(event: PlaybackStatusMachineEventType.ERROR | PlaybackStatusMachineEventType.UNLOAD): void;
};

/**
 * Actions which operate on multiple sub-domains within the `Metrics`
 * model
 */

const resolveMultiDomainActions = (value: Metrics, actions: TPlaybackActions): TMultiDomainActions => ({
    /**
     * Resets all values that are specific to the current media,
     * while maintaining all network metrics, playback status and
     * lifecycle status.
     */

    unload(event) {
        actions.setPlaybackStatus(event);

        const nextPlaybackStatus = value.playback.playbackStatus;
        const nextLifecycleStatus = value.playback.lifecycleStatus;

        value.network.activeVariantIndex = -1;
        value.playback = new Playback();
        value.timing = new Timing();

        value.playback.playbackStatus = nextPlaybackStatus;
        value.playback.lifecycleStatus = nextLifecycleStatus;
    },
});

export type {TMultiDomainActions};
export {resolveMultiDomainActions};
