import {drmControllerMessages} from '@DrmController/Constants/drmControllerMessages';
import {featureBandwidthEstimationMessages} from '@Features/BandwidthEstimation/Constants/featureBandwidthEstimationMessages';
import {featureCodecFilteringMessages} from '@Features/CodecFiltering/Constants/featureCodecFilteringMessages';
import {featureCodecSwitchingMessages} from '@Features/CodecSwitching/Constants/featureCodecSwitchingMessages';
import {dvrWindowRecoveryMessages} from '@Features/DvrWindowRecovery/Constants/dvrWindowRecoveryMessages';
import {featureEndingMessages} from '@Features/Ending/Constants/featureEndingMessages';
import {featureGapJumpingMessages} from '@Features/GapJumping/Constants/featureGapJumpingMessages';
import {featureLocalStorageMessages} from '@Features/LocalStorage/Constants/featureLocalStorageMessages';
import {featureManifestLoadingMessages} from '@Features/ManifestLoading/Constants/featureManifestLoadingMessages';
import {featureMultiPeriodDashMessages} from '@Features/MultiPeriodDash/Constants/featureMultiPeriodDashMessages';
import {featureMultiTrackAudioMessages} from '@Features/MultiTrackAudio/Constants/featureMultiTrackAudioMessages';
import {featurePersistedBandwidthEstimateMessages} from '@Features/PersistedBandwidthEstimate/Constants/featurePersistedBandwidthEstimateMessages';
import {featurePlaybackQualityMessages} from '@Features/PlaybackQuality/Constants/featurePlaybackQualityMessages';
import {featurePlayerHealthMessages} from '@Features/PlayerHealth/Constants/featurePlayerHealthMessages';
import {featureStallRecoveryMessages} from '@Features/StallRecovery/Constants/featureStallRecoveryMessages';
import {featureStartupMessages} from '@Features/Startup/Constants/featureStartupMessages';
import {featureVariantSwitchingMessages} from '@Features/VariantSwitching/Constants/featureVariantSwitchingMessages';
import {lifecycleManagerMessages} from '@LifecycleManager/Constants/lifecycleManagerMessages';
import {dashManifestParserMessages} from '@ManifestParsers/Dash/Constants/dashManifestParserMessages';
import {hlsManifestParserMessages} from '@ManifestParsers/Hls/Constants/hlsManifestParserMessages';
import {mediaElementControllerMessages} from '@MediaElementController/Constants/mediaElementControllerMessages';
import {downloaderMessages} from '@Network/Constants/downloaderMessages';
import {publicApiMessages} from '@Player/Constants/publicApiMessages';
import {segmentOrchestratorMessages} from '@SegmentOrchestrator/Constants/segmentOrchestratorMessages';
import {genericMessages} from '@Shared/Constants/genericMessages';
import {sourceBufferManagerMessages} from '@SourceBufferManager/Constants/sourceBufferManagerMessages';
import {transmuxerMessages} from '@SourceBufferManager/Constants/transmuxerMessages';
import {depoMessages} from '@State/Constants/depoMessages';
import {metricsStoreMessages} from '@State/Stores/Metrics/Constants/metricsStoreMessages';
import {variantOrchestratorMessages} from '@VariantOrchestrator/Constants/variantOrchestratorMessages';

import {MessageNamespace} from '../Constants/MessageNamespace';
import type {MessageType} from '../Constants/MessageType';
import type {TMessageId} from '../Types/TMessageId';

/**
 * A lookup table of all human readable "log", "warning", and "error" message for debugging. As this type of
 * text is heavy and does not minify well, it is extracted from the default build and loaded as needed as
 * part of the `DebugView`.
 *
 * Logs are still produced regardless at all times, but are stored as terse numeric values which are mapped
 * into their human readable equivalents via this lookup table only when the `DebugView` is activated.
 */

type TMessagesBySeverity = Record<MessageType, Record<string, (...args: any[]) => string>>;

const messageLookupTable: Record<MessageNamespace, TMessagesBySeverity> = {
    [MessageNamespace._000_DASH_MANIFEST_PARSER]: dashManifestParserMessages,
    [MessageNamespace._001_DEPO]: depoMessages,
    [MessageNamespace._002_DOWNLOADER]: downloaderMessages,
    [MessageNamespace._003_FEATURE_BANDWIDTH_ESTIMATION]: featureBandwidthEstimationMessages,
    [MessageNamespace._004_FEATURE_CODEC_SWITCHING]: featureCodecSwitchingMessages,
    [MessageNamespace._005_FEATURE_GAP_JUMPING]: featureGapJumpingMessages,
    [MessageNamespace._006_FEATURE_MANIFEST_LOADING]: featureManifestLoadingMessages,
    [MessageNamespace._008_FEATURE_MULTI_PERIOD_DASH]: featureMultiPeriodDashMessages,
    [MessageNamespace._007_FEATURE_VARIANT_SWITCHING]: featureVariantSwitchingMessages,
    [MessageNamespace._009_GENERIC]: genericMessages,
    [MessageNamespace._010_HLS_MANIFEST_PARSER]: hlsManifestParserMessages,
    [MessageNamespace._011_MEDIA_ELEMENT_CONTROLLER]: mediaElementControllerMessages,
    [MessageNamespace._012_PUBLIC_API]: publicApiMessages,
    [MessageNamespace._013_SEGMENT_ORCHESTRATOR_AUDIO]: segmentOrchestratorMessages,
    [MessageNamespace._014_SEGMENT_ORCHESTRATOR_VIDEO]: segmentOrchestratorMessages,
    [MessageNamespace._015_SOURCE_BUFFER_MANAGER_AUDIO]: sourceBufferManagerMessages,
    [MessageNamespace._016_SOURCE_BUFFER_MANAGER_VIDEO]: sourceBufferManagerMessages,
    [MessageNamespace._017_TRANSMUXER]: transmuxerMessages,
    [MessageNamespace._018_VARIANT_ORCHESTRATOR]: variantOrchestratorMessages,
    [MessageNamespace._019_DRM_CONTROLLER]: drmControllerMessages,
    [MessageNamespace._020_FEATURE_CODEC_FILTERING]: featureCodecFilteringMessages,
    [MessageNamespace._021_LIFECYCLE_MANAGER]: lifecycleManagerMessages,
    [MessageNamespace._022_DVR_WINDOW_RECOVERY]: dvrWindowRecoveryMessages,
    [MessageNamespace._023_FEATURE_ENDING]: featureEndingMessages,
    [MessageNamespace._024_FEATURE_PERSISTED_BANDWIDTH_ESTIMATE]: featurePersistedBandwidthEstimateMessages,
    [MessageNamespace._025_FEATURE_STALL_RECOVERY]: featureStallRecoveryMessages,
    [MessageNamespace._026_METRICS_STORE]: metricsStoreMessages,
    [MessageNamespace._027_PLAYBACK_QUALITY]: featurePlaybackQualityMessages,
    [MessageNamespace._028_FEATURE_STARTUP]: featureStartupMessages,
    [MessageNamespace._029_FEATURE_LOCAL_STORAGE]: featureLocalStorageMessages,
    [MessageNamespace._030_FEATURE_MULTI_TRACK_AUDIO]: featureMultiTrackAudioMessages,
    [MessageNamespace._031_FEATURE_PLAYER_HEALTH]: featurePlayerHealthMessages,
};

const renderHumanReadableMessage = (
    messageType: MessageType,
    namespace: MessageNamespace,
    logId: TMessageId,
    args: any[]
): string => {
    const render = messageLookupTable[namespace][messageType][logId];

    return render(...args);
};

export {renderHumanReadableMessage, messageLookupTable};
