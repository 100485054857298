import type {IWaitingInsideSourceBufferGapEvent} from '@Features/GapJumping/Interfaces/IWaitingInsideSourceBufferGapEvent';
import type {IWaitingOutsideSourceBufferGapEvent} from '@Features/GapJumping/Interfaces/IWaitingOutsideSourceBufferGapEvent';
import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeatureGapJumpingMessageId} from './FeatureGapJumpingMessageId';

const featureGapJumpingMessages = {
    [MessageType.LOG]: {},
    [MessageType.WARNING]: {
        [FeatureGapJumpingMessageId._000_GAP_ENTERED]: (seekRecoveryTimeSeconds: number): string =>
            `Gap in presentation timeline entered, jumping to ${seekRecoveryTimeSeconds}`,
        [FeatureGapJumpingMessageId._001_MANIFEST_GAP_ENTERED]: (recoverySeekToTimeSeconds: number): string =>
            `A manifest-gap entered, jumping to ${recoverySeekToTimeSeconds}s`,
        [FeatureGapJumpingMessageId._002_INSIDE_SOURCE_BUFFER_GAP_ENTERED]: ({
            bufferedRanges,
            currentTimeSeconds,
            nextGap,
            recoverySeekToTimeSeconds,
        }: IWaitingInsideSourceBufferGapEvent): string =>
            `An inside-source-buffer-gap entered, jumping the next gap in an attempt to recover:\n` +
            `   Current time:       ${currentTimeSeconds}s\n` +
            `   Buffered ranges:    ${bufferedRanges}\n` +
            `   Next gap:           ${nextGap}\n` +
            `   Jumping to:         ${recoverySeekToTimeSeconds}s\n`,
        [FeatureGapJumpingMessageId._003_OUTSIDE_SOURCE_BUFFER_GAP_ENTERED]: ({
            bufferedRanges,
            currentTimeSeconds,
            largestGap,
            recoverySeekToTimeSeconds,
        }: IWaitingOutsideSourceBufferGapEvent): string =>
            `An outside-source-buffer-gap entered. A waiting event has been emitted by the video element, ` +
            `but despite gaps in buffered ranges, the current time is beyond all of them. \n` +
            `⚠️ Gap position may be incorrectly reported by the Source Buffer, jumping the largest known gap in an ` +
            `attempt to recover:\n` +
            `   Current time:       ${currentTimeSeconds}s\n` +
            `   Buffered ranges:    ${bufferedRanges}\n` +
            `   Largest gap:        ${largestGap}\n` +
            `   Jumping to:         ${recoverySeekToTimeSeconds}s\n`,
    },
    [MessageType.ERROR]: {},
};

export type TFeatureGapJumpingMessages = TLoggerMessages<
    MessageNamespace._005_FEATURE_GAP_JUMPING,
    typeof featureGapJumpingMessages
>;

export {featureGapJumpingMessages};
