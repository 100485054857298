import {StreamType} from '@Shared/Constants/StreamType';
import {roundTo4DecimalPlaces} from '@Shared/Util/roundTo4DecimalPlaces';

import {ConfigAbr} from '../../Config/Models/ConfigAbr';
import {BufferHealthStatus} from '../Constants/BufferHealthStatus';
import {PlayheadStatus} from '../Constants/PlayheadStatus';
import {getSafeSeekRangeEndTimeSeconds} from '../Util/getSafeSeekRangeEndTimeSeconds';

import {BufferData} from './BufferData';

/**
 * Holds the current state of video, audio and text buffers, and all
 * related timing data.
 */

class Timing {
    /**
     * Linked reference to `config.abr`
     */

    public readonly configAbr: ConfigAbr = new ConfigAbr();

    /**
     * Linked reference to `config.behaviour.safeSeekEndOffsetSeconds`
     */

    public readonly configBehaviourSafeSeekEndOffsetSeconds: number = -1;

    /**
     * Linked reference to `manifest.isDynamic`, i.e. Linear or Live content
     */

    public readonly isDynamic: boolean = false;

    /**
     * Linked reference to `manifest.hasDynamicStreamEnded`
     */

    public readonly hasDynamicManifestEnded: boolean = false;

    /**
     * Linked reference to `manifest.seekRangeStartTime`
     */

    public readonly seekRangeStartTimeSeconds = NaN;

    /**
     * Linked reference to `manifest.seekRangeEndTime`
     */

    public readonly seekRangeEndTimeSeconds = NaN;

    /**
     * Linked reference to `manifest.suggestedPresentationDelaySeconds`
     */

    public readonly suggestedPresentationDelaySeconds = NaN;

    /**
     * All buffer data for the audio stream
     */

    public [StreamType.AUDIO]: BufferData = new BufferData();

    /**
     * All buffer data for the video stream
     */

    public [StreamType.VIDEO]: BufferData = new BufferData();

    /**
     * All buffer data for the text stream
     */

    public [StreamType.TEXT]: BufferData = new BufferData();

    /**
     * The current time of the video element.
     */

    public currentTimeSeconds: number = Timing.DEFAULT_CURRENT_TIME_SECONDS;

    /**
     * A smoothed, rolling average of the frequency of time updates.
     */

    public timeUpdateFrequencySeconds: number = 0;

    /**
     * The maximum gap offset that should be applied.
     */

    public get maxGapOffsetSeconds(): number {
        const {safeGapOffsetSeconds} = this.configAbr;

        // If `safeGapOffsetSeconds` has been set in the profile config,
        // we either use it or time update frequency x2, if set, whichever is higher.
        if (safeGapOffsetSeconds > 0 && this.timeUpdateFrequencySeconds) {
            return Math.max(safeGapOffsetSeconds, this.timeUpdateFrequencySeconds * 2);
        }

        return safeGapOffsetSeconds;
    }

    /**
     * The maximum effective hungry buffer-ahead duration, set dynamically during playback
     * based on available memory in response to `QUOTA_EXCEEDED` exceptions raised from
     * MSE.
     */

    public effectiveHungryBufferAheadDurationSeconds = Infinity;

    /**
     * An enum representing the current state of buffer health, inclusive
     * of small gaps.
     */

    public bufferHealthStatus: BufferHealthStatus = BufferHealthStatus.EMPTY;

    /**
     * An enum representing the position of the playhead in relation to buffered
     * and non-buffered ranges.
     */

    public playheadStatus: PlayheadStatus = PlayheadStatus.OUTSIDE_BUFFER;

    /**
     * The presentation time in seconds, at which the stream should be joined.
     */

    public joinTimeSeconds: number = -1;

    public get isBufferHealthy(): boolean {
        return this.bufferHealthStatus === BufferHealthStatus.HEALTHY;
    }

    public get audioBufferData(): BufferData {
        return this[StreamType.AUDIO];
    }

    public get videoBufferData(): BufferData {
        return this[StreamType.VIDEO];
    }

    public get audioBufferAheadDurationSeconds(): number {
        if (this.currentTimeSeconds < this.audioBufferData.bufferedStartTimeSeconds) return 0;

        return Math.max(0, this.audioBufferData.bufferedEndTimeSeconds - Math.max(0, this.currentTimeSeconds));
    }

    public get audioBufferingAheadDurationSeconds(): number {
        if (this.currentTimeSeconds < this.audioBufferData.bufferedStartTimeSeconds) return 0;

        return Math.max(0, this.audioBufferData.bufferingEndTimeSeconds - Math.max(0, this.currentTimeSeconds));
    }

    public get audioBufferBehindDurationSeconds(): number {
        if (this.currentTimeSeconds > this.audioBufferData.bufferedEndTimeSeconds) return 0;

        return Math.max(0, Math.max(0, this.currentTimeSeconds) - this.audioBufferData.bufferedStartTimeSeconds);
    }

    public get videoBufferAheadDurationSeconds(): number {
        if (this.currentTimeSeconds < this.videoBufferData.bufferedStartTimeSeconds) return 0;

        return Math.max(0, this.videoBufferData.bufferedEndTimeSeconds - Math.max(0, this.currentTimeSeconds));
    }

    public get videoBufferingAheadDurationSeconds(): number {
        if (this.currentTimeSeconds < this.videoBufferData.bufferedStartTimeSeconds) return 0;

        return Math.max(0, this.videoBufferData.bufferingEndTimeSeconds - Math.max(0, this.currentTimeSeconds));
    }

    public get videoBufferBehindDurationSeconds(): number {
        if (this.currentTimeSeconds > this.videoBufferData.bufferedEndTimeSeconds) return 0;

        return Math.max(0, Math.max(0, this.currentTimeSeconds) - this.videoBufferData.bufferedStartTimeSeconds);
    }

    public get combinedBufferAheadDurationSeconds(): number {
        return Math.min(this.audioBufferAheadDurationSeconds, this.videoBufferAheadDurationSeconds);
    }

    public get isInsideBuffer(): boolean {
        return this.playheadStatus === PlayheadStatus.INSIDE_BUFFER;
    }

    public get isInsideBufferOrGap(): boolean {
        return this.playheadStatus !== PlayheadStatus.OUTSIDE_BUFFER;
    }

    public get isInsideGap(): boolean {
        return this.playheadStatus === PlayheadStatus.INSIDE_GAP;
    }

    public get isBehindDvrWindow(): boolean {
        return this.isDynamic && this.seekRangeStartTimeSeconds > this.currentTimeSeconds;
    }

    public get isAtOrBeyondEndOfStream(): boolean {
        if (this.isDynamic && !this.hasDynamicManifestEnded) return false;

        // NB: Streams have been observed to stop/stall some distance before the expected
        // seek range end, so the stall threshold is subtracted.

        return this.timeRemainingSeconds <= this.configAbr.stallThresholdSeconds && this.seekRangeEndTimeSeconds > 0;
    }

    public get isDynamicAndNotEnded(): boolean {
        return this.isDynamic && !this.hasDynamicManifestEnded;
    }

    public get isEnding(): boolean {
        if (this.isDynamic && !this.hasDynamicManifestEnded) return false;

        return (
            this.timeRemainingSeconds <= this.configBehaviourSafeSeekEndOffsetSeconds &&
            this.seekRangeEndTimeSeconds > 0
        );
    }

    public get hasEndedOrIsEnding(): boolean {
        return this.isAtOrBeyondEndOfStream || this.isEnding;
    }

    public get distanceFromSeekRangeEndSeconds(): number {
        return roundTo4DecimalPlaces(this.seekRangeEndTimeSeconds - this.currentTimeSeconds);
    }

    public get distanceFromSeekRangeStartSeconds(): number {
        return roundTo4DecimalPlaces(this.currentTimeSeconds - this.seekRangeStartTimeSeconds);
    }

    public get timeRemainingSeconds(): number {
        return this.distanceFromSeekRangeEndSeconds;
    }

    public get safeSeekRangeEndTimeSeconds(): number {
        return getSafeSeekRangeEndTimeSeconds({
            bufferAheadGoal: this.configAbr.bufferAheadGoal,
            isDynamicAndNotEnded: this.isDynamicAndNotEnded,
            minPresentationDelay: this.configAbr.minPresentationDelay,
            safeSeekEndOffsetSeconds: this.configBehaviourSafeSeekEndOffsetSeconds,
            seekRangeEndTimeSeconds: this.seekRangeEndTimeSeconds,
            suggestedPresentationDelaySeconds: this.suggestedPresentationDelaySeconds,
        });
    }

    public static DEFAULT_CURRENT_TIME_SECONDS = -1;
}

export {Timing};
