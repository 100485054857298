import {AssetType} from '@Shared/Constants/AssetType';
import type {Manifest} from '@Shared/Models/Manifest';
import {hasElements} from '@Shared/Util/hasElements';

import type {TFinalSegmentPath} from '../../ManifestParsers/Dash/Types/TFinalSegmentPath';

const parseFinalSegmentPath = (
    manifest: Manifest,
    assetType: AssetType.AUDIO_SEGMENT | AssetType.VIDEO_SEGMENT
): [TFinalSegmentPath, boolean] => {
    const lastPeriodIndex = manifest.periods.length - 1;
    const lastPeriod = manifest.periods[lastPeriodIndex];

    if (!lastPeriod || !hasElements(lastPeriod.variants)) return [[-1, -1, -1], false];

    const firstVariantOfLastPeriod = lastPeriod.variants[0];

    const segmentRanges = {
        [AssetType.AUDIO_SEGMENT]: firstVariantOfLastPeriod.audioSegmentRanges,
        [AssetType.VIDEO_SEGMENT]: firstVariantOfLastPeriod.videoSegmentRanges,
    }[assetType];

    const segmentList = {
        [AssetType.AUDIO_SEGMENT]: firstVariantOfLastPeriod.audioSegments,
        [AssetType.VIDEO_SEGMENT]: firstVariantOfLastPeriod.videoSegments,
    }[assetType];

    const hasSegmentRanges = hasElements(segmentRanges);
    const lastSegmentRangeIndex = hasSegmentRanges ? segmentRanges.length - 1 : 0;
    const lastSegmentRange = segmentRanges[lastSegmentRangeIndex];

    const lastSegmentIndex = hasSegmentRanges
        ? lastSegmentRange.segmentCount - 1 + lastSegmentRange.startIndex
        : segmentList.length - 1;

    // If a segment list is present in the final period, signal that the final segment
    // instances of each variant must be explicitly marked as "final" at manifest parse time

    const shouldMarkFinalSegments = !hasSegmentRanges;

    return [[lastPeriodIndex, lastSegmentRangeIndex, lastSegmentIndex], shouldMarkFinalSegments];
};

export {parseFinalSegmentPath};
