import {PlayheadStatus} from '../Constants/PlayheadStatus';
import {PlayheadStatusMachineEventType} from '../Constants/PlayheadStatusMachineEventType';

const playheadStatusMachine = (
    prevPlayheadStatus: PlayheadStatus,
    eventType: PlayheadStatusMachineEventType
): PlayheadStatus => {
    switch (prevPlayheadStatus) {
        case PlayheadStatus.INSIDE_BUFFER:
            switch (eventType) {
                case PlayheadStatusMachineEventType.ENTER_GAP:
                    return PlayheadStatus.INSIDE_GAP;
                case PlayheadStatusMachineEventType.EXIT_BUFFER:
                    return PlayheadStatus.OUTSIDE_BUFFER;
                default:
                    return prevPlayheadStatus;
            }

        case PlayheadStatus.INSIDE_GAP:
            switch (eventType) {
                case PlayheadStatusMachineEventType.ENTER_BUFFER:
                    return PlayheadStatus.INSIDE_BUFFER;
                default:
                    return prevPlayheadStatus;
            }

        case PlayheadStatus.OUTSIDE_BUFFER:
            switch (eventType) {
                case PlayheadStatusMachineEventType.ENTER_BUFFER:
                    return PlayheadStatus.INSIDE_BUFFER;
                default:
                    return prevPlayheadStatus;
            }

        default:
            return prevPlayheadStatus;
    }
};

export {playheadStatusMachine};
