import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import type {StallRecoveryStrategy} from '@Shared/Constants/StallRecoveryStrategy';

import type {TProgressiveTimeUpdate} from '../Types/TProgressiveTimeUpdate';

import {FeatureStallRecoveryMessageId} from './FeatureStallRecoveryMessageId';

const featureStallRecoveryMessages = {
    [MessageType.LOG]: {
        [FeatureStallRecoveryMessageId._000_EXECUTING_STRATEGY]: (
            strategy: StallRecoveryStrategy,
            strategyIndex: number,
            strategyCount: number
        ): string => `Executing stall recovery strategy ${strategyIndex + 1} of ${strategyCount}: "${strategy}"`,
        [FeatureStallRecoveryMessageId._001_PLAYER_RECOVERED_FROM_STALL]: (): string => `Player recovered from stall`,
        [FeatureStallRecoveryMessageId._002_PLAYER_RECOVERED_FROM_STALL_INDEX]: (
            strategy: StallRecoveryStrategy,
            strategyIndex: number,
            strategyCount: number
        ): string =>
            `Player recovered from stall using ${strategy} strategy (${strategyIndex + 1} of ${strategyCount})`,
        [FeatureStallRecoveryMessageId._007_TIME_PROGRESSED_DURING_STALL_RECOVERY]: (
            prev: TProgressiveTimeUpdate,
            currentTimeSeconds: number
        ): string =>
            `Current time update has progressed from ${prev.currentTimeSeconds}s to ${currentTimeSeconds}s
                after ${prev.progressiveTimeUpdates} progressive time updates.
                Increasing progressive time update count.`,
    },
    [MessageType.WARNING]: {
        [FeatureStallRecoveryMessageId._003_PLAYER_FAILED_TO_RECOVER_FROM_STALL_INDEX]: (
            strategy: StallRecoveryStrategy,
            strategyIndex: number,
            strategyCount: number
        ): string => `Player failed to recover from stall using ${strategy} strategy (${strategyIndex + 1} of
            ${strategyCount})`,
        [FeatureStallRecoveryMessageId._004_STRATEGY_ATTEMPT_ABORTED]: (
            strategy: StallRecoveryStrategy,
            strategyIndex: number,
            strategyCount: number
        ): string => `Player aborted ${strategy} strategy due to an external seek or pause request
            (${strategyIndex} of ${strategyCount})`,
        [FeatureStallRecoveryMessageId._005_TIME_REGRESSED_DURING_STALL_RECOVERY]: (
            prev: TProgressiveTimeUpdate,
            currentTimeSeconds: number
        ): string =>
            `Current time update has regressed from ${prev.currentTimeSeconds}s to ${currentTimeSeconds}s
                after ${prev.progressiveTimeUpdates} progressive time updates. Resetting progressive time update count`,
        [FeatureStallRecoveryMessageId._006_TIME_UNCHANGED_DURING_STALL_RECOVERY]: (
            prev: TProgressiveTimeUpdate,
            currentTimeSeconds: number
        ): string =>
            `Current time update has remained unchanged at ${currentTimeSeconds}s
                after ${prev.progressiveTimeUpdates} progressive time updates.`,
        [FeatureStallRecoveryMessageId._008_REMOVING_DRM_KEY_SESSIONS_DUE_TO_UNEXPECTED_STALL]: () =>
            'Removing DRM key sessions due to unexpected stall',
    },
    [MessageType.ERROR]: {},
};

export type TFeatureStallRecoveryMessages = TLoggerMessages<
    MessageNamespace._025_FEATURE_STALL_RECOVERY,
    typeof featureStallRecoveryMessages
>;

export {featureStallRecoveryMessages};
