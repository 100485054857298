import type {SegmentRange} from '@Shared/Models/SegmentRange';
import {roundToTimescalePrecision} from '@Shared/Util/roundToTimescalePrecision';

type TSegmentDurationMap = Record<number, number>;

const deriveNormalSegmentDurationFromSegmentRanges = (segmentRanges: SegmentRange[]): number => {
    const segmentDurationMap: TSegmentDurationMap = segmentRanges.reduce(
        (map, {segmentDurationUnroundedSeconds, segmentCount, segmentTimescale}) => {
            const segmentDurationSeconds = roundToTimescalePrecision(segmentDurationUnroundedSeconds, segmentTimescale);

            map[segmentDurationSeconds] = map[segmentDurationSeconds] ?? 0;
            map[segmentDurationSeconds] += segmentCount;

            return map;
        },
        {} as TSegmentDurationMap
    );

    // The duration containing the greatest number of segments is the "normal" duration.

    const [normalSegmentDuration] = Object.keys(segmentDurationMap).reduce(
        (highest, key) => {
            const currentCount = segmentDurationMap[Number(key)];
            const [highestCount] = highest;

            if (currentCount > highestCount) {
                return [Number(key), currentCount];
            }

            return highest;
        },
        [-1, 0]
    );

    return normalSegmentDuration;
};

export {deriveNormalSegmentDurationFromSegmentRanges};
