import {getHumanReadableBitrate} from '@Shared/Util/getHumanReadableBitrate';
import {mean} from '@Shared/Util/mean';
import {roundTo1DecimalPlace} from '@Shared/Util/roundTo1DecimalPlaces';

import {ConfigAbr} from '../../Config/Models/ConfigAbr';
import {estimateLatencyInclusiveBandwidth} from '../Util/estimateLatencyInclusiveBandwidth';

/**
 * Contains all state relating to network metrics
 */

class Network {
    /**
     * Linked reference to `config.abr`
     */

    public readonly configAbr: ConfigAbr = new ConfigAbr();

    /**
     * Linked reference to `manifest.normalSegmentDuration`
     */

    public readonly normalSegmentDurationSeconds: number = -1;

    /**
     * Linked reference to `manifest.bitrates`
     */

    public readonly bitrates: number[] = [];

    /**
     * The index of the currently active variant, used to drive changes to the
     * manifest store's active variant.
     */

    public activeVariantIndex: number = -1;

    /**
     * An array of throughput-based bandwidth samples, used to derive a reasonable
     * rolling average over a constant number of samples.
     */

    public throughputBasedBandwidthSamples: number[] = [];

    /**
     * A slow-moving (high half-life) exponentially-weighted moving average based
     * on time-based bandwidth estimates received from successful video segment
     * downloads.
     */

    public timeBasedBandwidthEwmaSlow: number = -1;

    /**
     * A fast-moving (low half-life) exponentially-weighted moving average based
     * on time-based bandwidth estimates received from successful video segment
     * downloads.
     */

    public timeBasedBandwidthEwmaFast: number = -1;

    /**
     * An array of latency readings from successful video segment downloads, used to
     * derive a rolling average over a constant number of samples.
     */

    public requestLatencySamples: number[] = [];

    public get activeBitrate(): number {
        return this.bitrates[this.activeVariantIndex] || -1;
    }

    /**
     * The bitrate of the highest quality available variant. Used to determine how
     * much "redundancy" of bandwidth we have at any time.
     */

    public get maxBitrate(): number {
        return this.bitrates[this.bitrates.length - 1] || -1;
    }

    public get timeBasedBandwidthEstimate(): number {
        return Math.max(
            0,
            Math.min(this.timeBasedBandwidthEwmaSlow, this.timeBasedBandwidthEwmaFast) - this.configAbr.safetyOffsetBits
        );
    }

    public get latencyInclusiveTimeBasedBandwidthEstimate(): number {
        const latencySeconds = this.requestLatencyEstimate / 1000;

        const estimate = estimateLatencyInclusiveBandwidth(
            this.timeBasedBandwidthEstimate,
            latencySeconds,
            this.normalSegmentDurationSeconds
        );

        // Subtract `safetyOffsetBits` to yield a value that will ensure a
        // healthy buffer.

        return Math.max(0, Math.round(estimate - this.configAbr.safetyOffsetBits));
    }

    public get throughputBasedBandwidthEstimate(): number {
        if (this.throughputBasedBandwidthSamples.length < this.configAbr.maxRollingAverageSamples) return NaN;

        return Math.max(0, mean(this.throughputBasedBandwidthSamples) - this.configAbr.safetyOffsetBits);
    }

    /**
     * A multiple based on the highest available bitrate and estimated bandwidth,
     * describing the amount of additional (or redundant) bandwidth we have above
     * that which is needed to steadily play the highest available bitrate.
     */

    public get bandwidthRedundancyFactor(): number {
        // Return
        // - The bandwidth estimate divided by the current bitrate (rounded to 1 decimal place)
        // - or `1`, if not on highest bitrate

        return this.activeBitrate === this.maxBitrate
            ? roundTo1DecimalPlace(this.timeBasedBandwidthEstimate / this.activeBitrate)
            : 1;
    }

    public get requestLatencyEstimate(): number {
        return mean(this.requestLatencySamples) || 0;
    }

    public get humanReadableTimeBasedBandwidthEstimate(): string {
        return getHumanReadableBitrate(this.timeBasedBandwidthEstimate);
    }

    public get humanReadableThroughputBasedBandwidthEstimate(): string {
        return getHumanReadableBitrate(this.throughputBasedBandwidthEstimate);
    }

    /**
     * A boolean indicating whether a healthy buffer can be achieved with sequential
     * (i.e. non-overlapping) HTTP requests only (per source buffer). If latency is high,
     * this will be `false`, and overlapping requests may be needed regardless of
     * buffer health.
     *
     * When bandwidth is low (e.g. less than the lowest bitrate) regardless of latency,
     * overlapping requests will only deplete the buffer faster, so we also check whether
     * the non-latency-inclusive estimate is below the lowest bitrate.
     */

    public get canBufferSequentially(): boolean {
        return (
            this.latencyInclusiveTimeBasedBandwidthEstimate >= this.bitrates[0] ||
            this.timeBasedBandwidthEstimate <= this.bitrates[0]
        );
    }

    /**
     * An integer based on the floor of `bandwidthRedundancyFactor`, and
     * being no greater than `ConfigAbr.maxInFlightRequestsCount`.
     */

    public get maxInflightRequestsCount(): number {
        return Math.min(this.configAbr.maxInflightRequestsCount, Math.floor(this.bandwidthRedundancyFactor));
    }
}

export {Network};
