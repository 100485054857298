/**
 * Pads a numeric code with leading zeros for the required length.
 *
 * @param value number to be formatted
 * @param requiredLength required length of the formatted string
 */

const padWithLeadingZeros = (value: number, requiredLength: number): string => {
    const valueString = value.toString();
    const valueLength = valueString.length;

    if (valueLength >= requiredLength) {
        return valueString;
    }

    const leadingZeros = '0'.repeat(requiredLength - valueLength);

    return `${leadingZeros}${value}`;
};

export {padWithLeadingZeros};
