import {MediaElementReadyState} from '@Shared/Constants/MediaElementReadyState';
import {DrmSession} from '@State/Stores/Metrics/Models/DrmSession';

import {KeySessionStatus} from '../Constants/KeySessionStatus';
import {LifecycleStatus} from '../Constants/LifecycleStatus';
import {PlaybackStatus} from '../Constants/PlaybackStatus';
import {StalledStatus} from '../Constants/StalledStatus';

import {Quality} from './Quality';

class Playback {
    public quality: Quality = new Quality();

    /**
     * A linked reference to `config.behaviour.autoplay`
     */

    public readonly configBehaviourAutoplay: boolean = false;

    /**
     * A linked reference to `config.behaviour.allowZeroPlaybackRate`
     */

    public readonly configBehaviourAllowZeroPlaybackRate: boolean = false;

    /**
     * A state machine representing the current end-user facing state of the
     * underlying video element.
     */

    public playbackStatus: PlaybackStatus = PlaybackStatus.UNLOADED;

    /**
     * A state machine representing the current state of native MSE/EME
     * resources in relation the active playback session.
     */

    public lifecycleStatus: LifecycleStatus = LifecycleStatus.DOWN;

    /**
     * A boolean indicating whether the Content Decryption Module (CDM) is open
     * and key sessions can now be created.
     */

    public isCdmOpen = false;

    /**
     * An object representing the status and type of the current DRM key session
     */
    public keySession = new DrmSession();

    public isSeeking = false;

    /**
     * A boolean indicating whether the player is able to play meaning that content is
     * in the buffer and decoded/decrypted frames are available.
     *
     * This is set whenever the `canplay` event is dispatched from the video element, and unset
     * at manifest load and during seek outside the buffer.
     */

    public hasDecodedFrames = false;

    /**
     * An enum representing the most likely reason that playback has "stalled" (i.e. the current
     * time has stopped advancing). This is not reflected in the `playbackStatus` directly as there
     * are many legitimate reasons for a stall, some of which are already represented by other states
     * such as BUFFERING. Instead, this property is used to provide additional
     * observability over the causes of stalls, and to trigger potential recovery behaviour when
     * appropriate.
     */

    public stalledStatus = StalledStatus.NOT_STALLED;

    /**
     * A boolean indicating whether the player is jumping
     * `inside-source-buffer-gap` / `outside-source-buffer-gap` or not.
     *
     * This is set when `waiting` event is dispatched and unset whenever `timeupdate` event is dispatched
     * from the video element.
     */

    public isJumpingSourceBufferGap = false;

    /**
     * The current rate of playback, equivalent to the amount of
     * presentation time to be played through in 1 second (1 second by default).
     *
     * Higher if playback has been speeded up, and lower if slowed down.
     */

    public playbackRate: number = Playback.DEFAULT_PLAYBACK_RATE;

    /**
     * A boolean indicating if the native A/V pipeline (media source, source buffers, and key
     * sessions), are currently being replaced (e.g. due to a period change).
     */

    public isReplacingMediaPipeline: boolean = false;

    /*
     * A boolean indicating whether we established playback at least once for
     * the current media.
     */

    public hasPlayed: boolean = false;

    /**
     * The underlying media element's `readyState`.
     */

    public mediaElementReadyState: MediaElementReadyState = MediaElementReadyState.HAVE_NOTHING;

    public get isUnloaded(): boolean {
        return this.playbackStatus === PlaybackStatus.UNLOADED;
    }

    public get isLoading(): boolean {
        return this.playbackStatus === PlaybackStatus.LOADING;
    }

    public get isLoaded(): boolean {
        return this.playbackStatus === PlaybackStatus.LOADED;
    }

    public get isPlaying(): boolean {
        return this.playbackStatus === PlaybackStatus.PLAYING;
    }

    public get isPaused(): boolean {
        return this.playbackStatus === PlaybackStatus.PAUSED;
    }

    public get isBuffering(): boolean {
        return this.playbackStatus === PlaybackStatus.BUFFERING;
    }

    public get isErrored(): boolean {
        return this.playbackStatus === PlaybackStatus.ERRORED;
    }

    public get isUnloadedOrErrored(): boolean {
        return this.isUnloaded || this.isErrored;
    }

    public get hasEnded(): boolean {
        return this.playbackStatus === PlaybackStatus.ENDED;
    }

    public get isDestroyed(): boolean {
        return this.playbackStatus === PlaybackStatus.DESTROYED;
    }

    public get isUp(): boolean {
        return this.lifecycleStatus === LifecycleStatus.UP;
    }

    public get isDown(): boolean {
        return this.lifecycleStatus === LifecycleStatus.DOWN;
    }

    public get isTearingDown(): boolean {
        return this.lifecycleStatus === LifecycleStatus.TEARING_DOWN;
    }

    public get isKeySessionActive(): boolean {
        return this.keySession.status === KeySessionStatus.ACTIVE;
    }

    public get isKeySessionPending(): boolean {
        return this.keySession.status === KeySessionStatus.PENDING;
    }

    public get isKeySessionInactive(): boolean {
        return this.keySession.status === KeySessionStatus.INACTIVE;
    }

    public get hasStalled(): boolean {
        return this.stalledStatus !== StalledStatus.NOT_STALLED;
    }

    public get hasStalledUnexpectedly(): boolean {
        return this.stalledStatus === StalledStatus.STALLED_CAUSE_UNKNOWN;
    }

    public get hasStalledAtEnd(): boolean {
        return this.stalledStatus === StalledStatus.STALLED_AT_END;
    }

    public get isPlayableReadyState(): boolean {
        return this.mediaElementReadyState > MediaElementReadyState.HAVE_CURRENT_DATA;
    }

    public static DEFAULT_PLAYBACK_RATE = 1;
}

export {Playback};
