import {DEFAULT_LANGUAGE} from '@Features/MultiTrackAudio/Constants/languages';

/**
 * All configuration related to Audio.
 *
 * @title Audio
 */

class ConfigAudio {
    /**
     * Preferred language for multi-track audio. Typically 2-3 digit ISO code although
     * not guaranteed to align ISO standards. Unset when DEFAULT_LANGUAGE.
     */

    public language: string = DEFAULT_LANGUAGE;
}

export {ConfigAudio};
