import type {HyperErrorCode} from '@dazn/hyper';

import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import {AssetType} from '@Shared/Constants/AssetType';

import {DownloaderMessageId} from './DownloaderMessageId';

const downloaderMessages = {
    [MessageType.LOG]: {
        [DownloaderMessageId._000_DOWNLOAD_ABORTED]: (
            url: string,
            assetType: AssetType,
            requestDurationMs: number,
            isSideLoadedRequest?: boolean
        ): string =>
            `${renderAssetType(
                assetType,
                isSideLoadedRequest
            )} request aborted for "${url}" after ${requestDurationMs}ms`,
        [DownloaderMessageId._001_DOWNLOAD_COMPLETED]: (
            url: string,
            assetType: AssetType,
            totalBytes: number,
            waitingDurationMs: number,
            downloadDurationMs: number,
            isSideLoadedRequest?: boolean
        ): string =>
            `${renderAssetType(
                assetType,
                isSideLoadedRequest
            )} request completed for "${url}" succeeded with a response size of ` +
            `${totalBytes}B, a time to first byte of ${waitingDurationMs}ms, and a download ` +
            `duration of ${downloadDurationMs}ms`,
        [DownloaderMessageId._003_DOWNLOAD_LATENCY_OVERLAP_POINT]: (url: string): string =>
            `Triggering latency overlap point for request "${url}"`,
        [DownloaderMessageId._004_DOWNLOAD_STARTED]: (
            url: string,
            assetType: AssetType,
            isSideLoadedRequest?: boolean
        ): string => `${renderAssetType(assetType, isSideLoadedRequest)} request started for "${url}"`,
        [DownloaderMessageId._006_DOWNLOAD_RETRY]: (
            url: string,
            assetType: AssetType,
            isSideLoadedRequest?: boolean
        ): string => `${renderAssetType(assetType, isSideLoadedRequest)} request retry for "${url}"`,
    },
    [MessageType.WARNING]: {
        [DownloaderMessageId._002_DOWNLOAD_ERRORED]: (
            assetUrl: string,
            hyperCode: HyperErrorCode,
            httpStatus: number,
            assetType: AssetType,
            responseText: string,
            isSideLoadedRequest?: boolean
        ): string => {
            let message = `${renderAssetType(
                assetType,
                isSideLoadedRequest
            )} request errored for "${assetUrl}" with code "${hyperCode}"`;

            message = httpStatus === -1 ? message : `${message} and HTTP status "${httpStatus}"`;
            message = responseText === '' ? message : `${message} and response text "${responseText}"`;

            return message;
        },
        [DownloaderMessageId._007_SLOW_LICENSE_SERVER_DETECTED]: (
            licenseUrl: string,
            requestDurationMs: number
        ): string =>
            `A license request to "${licenseUrl}" failed to respond within ${requestDurationMs}. ` +
            'The request was aborted.',
        [DownloaderMessageId._005_NO_CONTENT_LENGTH]: (url: string, estimatedBits: number): string =>
            `"VIDEO_SEGMENT" request at "${url}" responded with no \`Content-Length\` header. ` +
            `Falling back to best estimate ${estimatedBits}b`,
    },
    [MessageType.ERROR]: {},
};

const renderAssetType = (assetType: AssetType, isSideLoadedRequest: boolean = false): string => {
    if (assetType !== AssetType.MASTER_MANIFEST || !isSideLoadedRequest) return `"${assetType}"`;

    return `Side-loaded "${assetType}"`;
};

export type TDownloaderMessages = TLoggerMessages<MessageNamespace._002_DOWNLOADER, typeof downloaderMessages>;

export {downloaderMessages};
