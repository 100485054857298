import type {FC} from 'react';

import {MessageType} from '@Logger/Constants/MessageType';
import {DebugView} from '@Logger/DebugView/DebugView';
import type {TMessage} from '@Logger/Types/TMessage';
import {createState} from '@State/createState';
import {createLocalStorageStore} from '@State/Stores/LocalStorage/createLocalStorageStore';

const state = createState();
const localStorageStore = createLocalStorageStore(state);
const debugView = new DebugView(localStorageStore);

const messageTypeToString = (messageType: MessageType): string => {
    switch (messageType) {
        case MessageType.LOG:
            return 'log';
        case MessageType.WARNING:
            return 'warning';
        case MessageType.ERROR:
            return 'error';
    }
};

const Results: FC<{data: TMessage[]}> = ({data = []}) => (
    <ul className="Results">
        {data.map((log: TMessage, index: number) => {
            const rtn = debugView.renderMessage(log);

            if (!rtn) return null;
            const text = rtn.logText;
            const colour = rtn.colour;
            const messageType = messageTypeToString(rtn.messageType);

            return (
                <li className="Results__result" data-type={messageType} key={index} style={{color: colour}}>
                    {text}
                </li>
            );
        })}
    </ul>
);

export {Results};
