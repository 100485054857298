import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {DvrWindowRecoveryMessageId} from './DvrWindowRecoveryMessageId';

const dvrWindowRecoveryMessages = {
    [MessageType.LOG]: {
        [DvrWindowRecoveryMessageId._000_BEHIND_DVR_WINDOW]: (
            behindBySeconds: number,
            recoveryTimeSeconds: number
        ): string =>
            `Current time is ${behindBySeconds}s behind minimum seek range, recovering to ${recoveryTimeSeconds}s`,
    },
    [MessageType.WARNING]: {},
    [MessageType.ERROR]: {},
};

export type TDvrWindowRecoveryMessages = TLoggerMessages<
    MessageNamespace._022_DVR_WINDOW_RECOVERY,
    typeof dvrWindowRecoveryMessages
>;

export {dvrWindowRecoveryMessages};
