import type {TFinalSegmentPath} from '@ManifestParsers/Dash/Types/TFinalSegmentPath';
import {DynamicManifestStatus} from '@Shared/Constants/DynamicManifestStatus';
import type {ISecurityToken} from '@Shared/Interfaces/ISecurityToken';
import {hasElements} from '@Shared/Util/hasElements';
import {isMatchingHostname} from '@Shared/Util/isMatchingHostname';
import {parseUrl} from '@Shared/Util/parseUrl';
import {deriveNormalSegmentDurationFromSegmentList} from '@State/Stores/Manifest/Util/deriveNormalSegmentDurationFromSegmentList';
import {deriveNormalSegmentDurationFromSegmentRanges} from '@State/Stores/Manifest/Util/deriveNormalSegmentDurationFromSegmentRanges';

import {ManifestFormat} from '../Constants/ManifestFormat';
import {ManifestType} from '../Constants/ManifestType';
import {roundTo4DecimalPlaces} from '../Util/roundTo4DecimalPlaces';

import type {Period} from './Period';
import {TimedMetadata} from './TimedMetadata';
import type {Variant} from './Variant';

class Manifest {
    /**
     * The URL of the primary, master manifest.
     */

    public url: string = '';

    /**
     * The time to begin playback, for the loaded manifest.
     */

    public startTimeSeconds: number = -1;

    /**
     * If a period with this ID is present in the returned manifest,
     * join the manifest from the start of that period
     */
    public preferredPeriodId: string | null = null;

    /**
     * The type of manifest. `STATIC` manifests (e.g. for VOD) are
     * parsed once. `DYNAMIC` manifests (e.g. LIVE) update over time and
     * must be re-parsed periodically. `NONE` represents the default
     * unloaded state.
     */

    public type: ManifestType = ManifestType.NONE;

    /**
     * The format of the underlying manifest. (e.g. HLS, DASH).
     */

    public format: ManifestFormat = ManifestFormat.NONE;

    /**
     * The number of seconds that the player should initially buffer for
     * before attempting playback.
     */

    public minimumBufferTimeSeconds: number = -1;

    /**
     * The suggested duration between the playhead and the live edge for
     * live streams.
     */

    public suggestedPresentationDelaySeconds: number = -1;

    /**
     * The duration of the stream, or the number of seconds between the live edge
     * and the start of the DVR window.
     */

    public mediaPresentationDurationSeconds: number = -1;

    /**
     * Earliest availability time (seek range start) for a dynamic manifest in UTC.
     */

    public availabilityStartTimeSeconds: number = 0;

    /**
     * Time of the latest update to a dynamic manifest, if dynamic.
     */

    public publishTimeSeconds: number = 0;

    /**
     * The minimum interval at which the manifest shall be updated, and
     * therefore re-parsed.
     */

    public minimumUpdatePeriodSeconds: number = -1;

    /**
     * The duration of segment availability on the server (CDN) or the maximum rewind (DVR) window.
     * Defaults to `Infinity` by default as segments are persisted indefinitely.
     */

    public segmentAvailabilityDurationSeconds: number = Infinity;

    /**
     * The maximum duration of a segment.
     */

    public maxSegmentDurationSeconds: number = -1;

    /**
     * A common base URL for all segments and variant manifests
     * contained within the master manifest.
     */

    public base: string = '';

    /**
     * A list of all currently available periods for the loaded manifest.
     */

    public periods: Period[] = [];

    /**
     * The index of the currently active (elapsing the current time) period.
     */

    public activePeriodIndex: number = 0;

    /**
     * The index of the currently active (downloading) variant.
     */

    public activeVariantIndex: number = -1;

    /**
     * The path to the final audio segment (variant index > range index > segment index),
     * if a static manifest.
     */

    public finalAudioSegmentPath: TFinalSegmentPath = [-1, -1, -1];

    /**
     * The path to the final audio segment (variant index > range index > segment index),
     * if a static manifest.
     */

    public finalVideoSegmentPath: TFinalSegmentPath = [-1, -1, -1];

    /**
     * The path to the final text segment (period index > range index > segment index),
     * if a static manifest.
     */

    public finalTextSegmentPath: TFinalSegmentPath = [-1, -1, -1];

    /**
     * All data relating to "timed metadata", which refers to regions of time or individual
     * timestamped events that exist relative to duration/timeline of the loaded media and have
     * some sort of associated metadata. Typically used for dynamic advertising insertion (DAI)
     * and reporting purposes.
     */

    public timedMetadata: TimedMetadata = new TimedMetadata();

    /**
     * An enum used to track the state of a dynamic manifest, and detect prolonged
     * pending updates, or its final transition into an ended state.
     */

    public dynamicManifestStatus: DynamicManifestStatus = DynamicManifestStatus.SYNCED;

    /**
     * A security token to be appended to all manifest updates and segment request for the currently
     * loaded manifest.
     */

    public token: ISecurityToken | null = null;

    public get hostname(): string | undefined {
        return parseUrl(this.url)?.hostname;
    }

    public get isMatchingHostname(): boolean {
        return isMatchingHostname(this.token, this.hostname);
    }

    public get hasManifestUrl(): boolean {
        return this.url !== '';
    }

    public get isParsed(): boolean {
        return this.type !== ManifestType.NONE;
    }

    public get isNotParsed(): boolean {
        return !this.isParsed;
    }

    /**
     * LINEAR or LIVE content
     */
    public get isDynamic(): boolean {
        return this.type === ManifestType.DYNAMIC;
    }

    /**
     * VoD
     */
    public get isStatic(): boolean {
        return this.type === ManifestType.STATIC;
    }

    public get isHls(): boolean {
        return this.format === ManifestFormat.HLS;
    }

    public get isDash(): boolean {
        return this.format === ManifestFormat.DASH;
    }

    public get activePeriod(): Period | null {
        return this.periods[this.activePeriodIndex] || null;
    }

    public get isMasterManifestParsed(): boolean {
        return hasElements(this.variants);
    }

    public get isActiveVariantParsed(): boolean {
        return (
            this.activeVariant !== null &&
            this.activeVariant.hasLoadedVideoSegmentsMetadata &&
            this.activeVariant.hasLoadedAudioSegmentsMetadata
        );
    }

    public get durationSeconds(): number {
        return roundTo4DecimalPlaces(this.seekRangeEndTimeSeconds - this.seekRangeStartTimeSeconds);
    }

    /**
     * Sums the segment count of each period (using the active variant index or `0`).
     */

    public get segmentCount(): number {
        return this.periods.reduce((segmentCount, period) => {
            const activeOrFirstParsedVariantInPeriod = period.variants[Math.max(0, this.activeVariantIndex)];

            return segmentCount + activeOrFirstParsedVariantInPeriod.segmentCount;
        }, 0);
    }

    /**
     * A list of all available variants for the loaded manifest.
     */

    public get variants(): Variant[] {
        if (!this.activePeriod) return [];

        return this.activePeriod.variants;
    }

    public get activeVariant(): Variant | null {
        return this.variants[this.activeVariantIndex] || null;
    }

    public get seekRangeStartTimeSeconds(): number {
        const firstPeriod = this.periods[0];

        if (!firstPeriod) return 0;

        const firstVariant = firstPeriod.variants[0];

        if (!firstVariant) return 0;

        let audioStartTime = 0;
        let videoStartTime = 0;

        const [firstAudioSegment] = firstVariant.audioSegments;
        const [firstVideoSegment] = firstVariant.videoSegments;
        const [firstAudioSegmentRange] = firstVariant.audioSegmentRanges;
        const [firstVideoSegmentRange] = firstVariant.videoSegmentRanges;

        if (firstAudioSegmentRange && firstVideoSegmentRange) {
            audioStartTime = firstAudioSegmentRange.startTimeSeconds;
            videoStartTime = firstVideoSegmentRange.startTimeSeconds;
        } else if (firstAudioSegment && firstVideoSegment) {
            audioStartTime = firstAudioSegment.startTimeSeconds;
            videoStartTime = firstVideoSegment.startTimeSeconds;
        }

        return Math.max(videoStartTime, audioStartTime);
    }

    public get audioSeekRangeEndTimeSeconds(): number {
        const lastPeriod = this.periods[this.periods.length - 1];

        if (!lastPeriod) return 0;

        const firstVariant = lastPeriod.variants[0];

        if (!firstVariant) return 0;

        let audioEndTimeSeconds = 0;

        const lastAudioSegment = firstVariant.audioSegments[firstVariant.audioSegments.length - 1];
        const lastAudioSegmentRange = firstVariant.audioSegmentRanges[firstVariant.audioSegmentRanges.length - 1];

        if (lastAudioSegment) {
            audioEndTimeSeconds = lastAudioSegment.endTimeSeconds;
        } else if (lastAudioSegmentRange) {
            audioEndTimeSeconds = lastAudioSegmentRange.endTimeSeconds;
        }

        return audioEndTimeSeconds;
    }

    public get videoSeekRangeEndTimeSeconds(): number {
        const lastPeriod = this.periods[this.periods.length - 1];

        if (!lastPeriod) return 0;

        const firstVariant = lastPeriod.variants[0];

        if (!firstVariant) return 0;

        let videoEndTimeSeconds = 0;

        const lastVideoSegment = firstVariant.videoSegments[firstVariant.videoSegments.length - 1];
        const lastVideoSegmentRange = firstVariant.videoSegmentRanges[firstVariant.videoSegmentRanges.length - 1];

        if (lastVideoSegment) {
            videoEndTimeSeconds = lastVideoSegment.endTimeSeconds;
        } else if (lastVideoSegmentRange) {
            videoEndTimeSeconds = lastVideoSegmentRange.endTimeSeconds;
        }

        return videoEndTimeSeconds;
    }

    public get seekRangeEndTimeSeconds(): number {
        return Math.min(this.audioSeekRangeEndTimeSeconds, this.videoSeekRangeEndTimeSeconds);
    }

    public get highestFrameEndTimeSeconds(): number {
        return Math.max(this.audioSeekRangeEndTimeSeconds, this.videoSeekRangeEndTimeSeconds);
    }

    public get normalSegmentDurationSeconds(): number {
        const activeVariant = this.activeVariant ?? this.variants[0];

        if (!activeVariant) return -1;

        const {videoSegmentRanges, videoSegments} = activeVariant;

        if (hasElements(videoSegmentRanges)) {
            // Attempt range-based approach first

            return deriveNormalSegmentDurationFromSegmentRanges(videoSegmentRanges);
        }

        // Else fallback to list-based approach

        return deriveNormalSegmentDurationFromSegmentList(videoSegments);
    }

    public get bitrates(): number[] {
        return this.variants.map(variant => variant.bitrate);
    }

    public get isDynamicManifestSynced(): boolean {
        return this.dynamicManifestStatus === DynamicManifestStatus.SYNCED;
    }

    public get isDynamicManifestPendingUpdate(): boolean {
        return this.dynamicManifestStatus === DynamicManifestStatus.PENDING_UPDATE;
    }

    public get hasDynamicManifestEnded(): boolean {
        return this.dynamicManifestStatus === DynamicManifestStatus.ENDED;
    }
}

export {Manifest};
