import {createCodecFilterer} from '@Features/CodecFiltering/Util/createCodecFilterer';
import {hasLiveStreamEnded} from '@Features/Ending/Util/hasLiveStreamEnded';
import {FeatureManifestLoadingMessageId} from '@Features/ManifestLoading/Constants/FeatureManifestLoadingMessageId';
import {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {logger} from '@Logger/logger';
import {AssetType} from '@Shared/Constants/AssetType';
import {DynamicManifestStatus} from '@Shared/Constants/DynamicManifestStatus';
import type {ISecurityToken} from '@Shared/Interfaces/ISecurityToken';
import {Manifest} from '@Shared/Models/Manifest';
import type {Segment} from '@Shared/Models/Segment';
import {findIndex} from '@Shared/Util/findIndex';
import {parseFinalSegmentPath} from '@Shared/Util/parseFinalSegmentPath';

type TManifestActions = {
    dropVariantIndex(): void;
    endDynamicManifest(): void;
    loadDashManifest(
        manifestRaw: Manifest,
        dependencies?: Pick<ReturnType<typeof createCodecFilterer>, 'filterCodecsBySupport'>
    ): boolean;
    loadHlsAudioVariantManifest(audioSegments: Segment[], variantIndex: number): void;
    loadHlsMasterManifest(manifest: Manifest): void;
    loadHlsVideoVariantManifest(videoSegments: Segment[], variantIndex: number): void;
    setActivePeriodIndex(activePeriodIndex: number): void;
    setActiveVariantIndex(activeVariantIndex: number): boolean;
    setManifestUrl(url: string): void;
    setStartTime(startTimeSeconds: number): void;
    setPreferredPeriodId(preferredPeriodId: string): void;
    setToken(token: ISecurityToken): void;
    unload(): void;
};

const resolveManifestActions = (value: Manifest, actions: TManifestActions): TManifestActions => {
    const {filterCodecsBySupport, resetCodecFilterer} = createCodecFilterer();

    return {
        /**
         * Sets the manifest URL to be loaded.
         */

        setManifestUrl(url) {
            value.url = url;
        },

        /**
         * Sets the time to begin playback from.
         */

        setStartTime(startTimeSeconds) {
            value.startTimeSeconds = startTimeSeconds;
        },

        /**
         * Sets the period ID to begin playback from.
         */

        setPreferredPeriodId(preferredPeriodId) {
            value.preferredPeriodId = preferredPeriodId;
        },

        /**
         * Sets a security token to be appended to all manifest updates and segment request for the
         * currently loaded manifest.
         */

        setToken(token: ISecurityToken) {
            value.token = token;
        },

        /**
         * Unloads the currently loaded manifest by
         * resetting the store to its default values.
         */

        unload() {
            if (!value.hasManifestUrl) return;

            Object.assign(value, new Manifest());

            resetCodecFilterer();
        },

        /**
         * Loads an already parsed DASH manifest.
         */

        loadDashManifest(manifest, dependencies = {filterCodecsBySupport}) {
            const {
                activeVariantIndex: prevActiveVariantIndex,
                activePeriodIndex: prevActivePeriodIndex,
                url: prevUrl,
                publishTimeSeconds: prevPublishTimeSeconds,
                token,
            } = value;

            // Serialise depo proxy to ensure values are not wiped

            const prevToken = (token?.valueOf() as ISecurityToken) ?? null;

            const prevActivePeriodId = value.activePeriod?.id || '';

            manifest.periods = dependencies.filterCodecsBySupport(manifest.periods);

            switch (true) {
                case value.isNotParsed:
                    // Initial VOD or LIVE manifest load from an unloaded state

                    Object.assign(value, manifest);

                    // Ensure stateful properties are maintained

                    value.token = prevToken;

                    logger.log(
                        MessageNamespace._006_FEATURE_MANIFEST_LOADING,
                        FeatureManifestLoadingMessageId._003_DASH_MANIFEST_PARSED,
                        value.type
                    );

                    // Return true to indicate initial load

                    return true;
                case value.isStatic:
                    // Replacing over the top of a loaded VOD manifest

                    Object.assign(value, manifest);

                    // Ensure stateful properties are maintained

                    value.activeVariantIndex = prevActiveVariantIndex;
                    value.activePeriodIndex = prevActivePeriodIndex;
                    value.token = prevToken;

                    logger.log(
                        MessageNamespace._006_FEATURE_MANIFEST_LOADING,
                        FeatureManifestLoadingMessageId._002_DASH_STATIC_MANIFEST_REPLACED
                    );

                    break;
                case value.isDynamic:
                    // Updating or replacing over the top of a loaded live manifest

                    if (manifest.url === prevUrl) {
                        if (manifest.publishTimeSeconds > prevPublishTimeSeconds || manifest.isStatic) {
                            value.dynamicManifestStatus = DynamicManifestStatus.SYNCED;
                        } else {
                            // If the manifest version on the CDN has not been updated since the last
                            // update, do not update state wastefully. Additionally, this may indicate
                            // the end of a live stream over a long enough period, so we flag it.

                            value.dynamicManifestStatus = DynamicManifestStatus.PENDING_UPDATE;

                            break;
                        }

                        // URL is the same, normal live manifest update, only
                        // time-sensitive values need be updated

                        value.publishTimeSeconds = manifest.publishTimeSeconds;
                        value.segmentAvailabilityDurationSeconds = manifest.segmentAvailabilityDurationSeconds;
                        value.periods = manifest.periods;
                        value.timedMetadata = manifest.timedMetadata;

                        // If the live stream has ended, immediately put it into an ended state.

                        if (hasLiveStreamEnded(value, manifest)) actions.endDynamicManifest();

                        // The index of the active period may have changed due to DVR window,
                        // find it by ID:

                        const activePeriodIndex = Math.max(
                            0,
                            findIndex(manifest.periods, period => period.id === prevActivePeriodId)
                        );

                        value.activePeriodIndex = activePeriodIndex;

                        logger.log(
                            MessageNamespace._006_FEATURE_MANIFEST_LOADING,
                            FeatureManifestLoadingMessageId._000_DASH_DYNAMIC_MANIFEST_UPDATED
                        );
                    } else {
                        // URLs are different, replacing

                        Object.assign(value, manifest);

                        // Ensure stateful properties are maintained

                        value.activeVariantIndex = prevActiveVariantIndex;
                        value.activePeriodIndex = prevActivePeriodIndex;
                        value.token = prevToken;

                        logger.log(
                            MessageNamespace._006_FEATURE_MANIFEST_LOADING,
                            FeatureManifestLoadingMessageId._001_DASH_DYNAMIC_MANIFEST_REPLACED
                        );
                    }

                    break;
            }

            return false;
        },

        /**
         * Loads an HLS master manifest. Includes all data minus the
         * individual segments of each variant, which are loaded asynchronously on
         * adaptation.
         */

        loadHlsMasterManifest(manifest) {
            Object.assign(value, manifest);

            logger.log(
                MessageNamespace._006_FEATURE_MANIFEST_LOADING,
                FeatureManifestLoadingMessageId._004_HLS_MASTER_MANIFEST_PARSED
            );
        },

        /**
         * Completes the parsing and mapping of an existing HLS `Variant` with an array of
         * mapped video segments, using the provided HLS "media playlist".
         */

        loadHlsVideoVariantManifest(videoSegments, variantIndex) {
            const variantToMap = value.variants[variantIndex];

            variantToMap.videoSegments = videoSegments;

            logger.log(
                MessageNamespace._006_FEATURE_MANIFEST_LOADING,
                FeatureManifestLoadingMessageId._005_HLS_MEDIA_MANIFEST_VIDEO_PARSED_OF_VARIANT_INDEX,
                variantIndex
            );
        },

        /**
         * Populates an existing HLS `Variant` with an array of mapped
         * audio segments, using a parsed HLS "media playlist".
         */

        loadHlsAudioVariantManifest(audioSegments, variantIndex) {
            const variantToMap = value.variants[variantIndex];

            variantToMap.audioSegments = audioSegments;

            logger.log(
                MessageNamespace._006_FEATURE_MANIFEST_LOADING,
                FeatureManifestLoadingMessageId._006_HLS_MEDIA_MANIFEST_AUDIO_PARSED_OF_VARIANT_INDEX,
                variantIndex
            );
        },

        setActivePeriodIndex(nextActivePeriodIndex) {
            value.activePeriodIndex = nextActivePeriodIndex;
        },

        setActiveVariantIndex(activeVariantIndex) {
            value.activeVariantIndex = activeVariantIndex;

            return value.isActiveVariantParsed;
        },

        dropVariantIndex(): void {
            value.activeVariantIndex--;
        },

        endDynamicManifest() {
            value.dynamicManifestStatus = DynamicManifestStatus.ENDED;

            const [finalAudioSegmentPath] = parseFinalSegmentPath(value, AssetType.AUDIO_SEGMENT);
            const [finalVideoSegmentPath] = parseFinalSegmentPath(value, AssetType.VIDEO_SEGMENT);

            value.finalAudioSegmentPath = finalAudioSegmentPath;
            value.finalVideoSegmentPath = finalVideoSegmentPath;
        },
    };
};

export type {TManifestActions};
export {resolveManifestActions};
