import {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {logger} from '@Logger/logger';
import type {Period} from '@Shared/Models/Period';
import {hasElements} from '@Shared/Util/hasElements';
import {throwPlayerError} from '@Shared/Util/throwPlayerError';

import {FeatureCodecFilteringMessageId} from '../Constants/FeatureCodecFilteringMessageId';

import {isCodecSupported} from './isCodecSupported.default';

interface ICreateCodecsFiltererReturnType {
    filterCodecsBySupport: (periods: Period[]) => Period[];
    resetCodecFilterer: () => void;
}

interface IDependencies {
    isCodecSupported: typeof isCodecSupported;
}

/**
 * Creates and returns two closed over functions for checking the codec support of new periods
 * coming into the player.
 *
 * A local cache of "processed periods" is maintained within closure to
 * ensure the minimal amount of iteration is performed on each manifest update.
 */

const createCodecFilterer = (dependencies: IDependencies = {isCodecSupported}): ICreateCodecsFiltererReturnType => {
    let processedPeriods: Record<string, true> = {};

    /**
     * Receives the latest list of periods, and returns an updated list of periods with any unsupported variants
     * within a period filtered out.
     *
     * Mutates the data passed in for performance reasons.
     */

    const filterCodecsBySupport = (periods: Period[]): Period[] => {
        let isPristine = true;

        // Perf: Reverse through the periods, logic being that newer ones will always be inserted at
        // the end / live edge. It is possible that new periods could be inserted at any arbitrary point,
        // but we accept that risk in favour of the performance benefits of this approach.

        for (let i = periods.length - 1; i >= 0; i--) {
            const period = periods[i];

            if (processedPeriods[period.id]) {
                // We have already processed this period (and all periods before it), so stop
                // iteration here.

                break;
            }

            // Check each variant within the period
            period.variants = period.variants.filter(variant => {
                // Verify codecs
                const supportsAudio = dependencies.isCodecSupported(variant.audioMime);
                const supportsVideo = dependencies.isCodecSupported(variant.videoMime);

                if (!supportsAudio) {
                    logger.warn(
                        MessageNamespace._020_FEATURE_CODEC_FILTERING,
                        FeatureCodecFilteringMessageId._000_UNSUPPORTED_AUDIO_CODEC,
                        variant.audioCodec,
                        variant.periodId,
                        variant.variantIndex
                    );
                }

                if (!supportsVideo) {
                    logger.warn(
                        MessageNamespace._020_FEATURE_CODEC_FILTERING,
                        FeatureCodecFilteringMessageId._001_UNSUPPORTED_VIDEO_CODEC,
                        variant.videoCodec,
                        variant.periodId,
                        variant.variantIndex
                    );
                }

                const isPlayable = supportsAudio && supportsVideo;

                if (!isPlayable) isPristine = false;

                return isPlayable;
            }, []);

            // Check the period has at least one variant

            if (!hasElements(period.variants)) {
                throwPlayerError(
                    MessageNamespace._020_FEATURE_CODEC_FILTERING,
                    FeatureCodecFilteringMessageId._002_NO_PLAYABLE_VARIANTS,
                    period
                );
            }

            // Mark period as processed

            processedPeriods[period.id] = true;
        }

        if (isPristine) {
            logger.log(
                MessageNamespace._020_FEATURE_CODEC_FILTERING,
                FeatureCodecFilteringMessageId._003_ALL_CODECS_SUPPORTED
            );
        }

        return periods;
    };

    /**
     * Resets the `processedPeriods` cache (e.g. in response to a new manifest).
     */

    const resetCodecFilterer = () => (processedPeriods = {});

    return {
        filterCodecsBySupport,
        resetCodecFilterer,
    };
};

export type {IDependencies};
export {createCodecFilterer};
