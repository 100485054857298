import type {TEnumerationValue} from '@Shared/Util/enumeration';

// this enum is part of public API so will be removed in 2 steps
// eslint-disable-next-line
enum AssetType {
    AUDIO_INIT_SEGMENT = 'AUDIO_INIT_SEGMENT',
    AUDIO_SEGMENT = 'AUDIO_SEGMENT',
    AUDIO_VARIANT_MANIFEST = 'AUDIO_VARIANT_MANIFEST',
    LICENSE = 'LICENSE',
    MASTER_MANIFEST = 'MASTER_MANIFEST',
    VIDEO_INIT_SEGMENT = 'VIDEO_INIT_SEGMENT',
    VIDEO_SEGMENT = 'VIDEO_SEGMENT',
    VIDEO_VARIANT_MANIFEST = 'VIDEO_VARIANT_MANIFEST',
}

// it's used as gradual usage migration for consumers
type TAssetType = TEnumerationValue<typeof AssetType>;

export type {TAssetType};
export {AssetType};
