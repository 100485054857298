import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeatureMultiTrackAudioMessageId} from './FeatureMultiTrackAudioMessageId';

const featureMultiTrackAudioMessages = {
    [MessageType.LOG]: {
        [FeatureMultiTrackAudioMessageId._000_ACTIVE_AUDIO_TRACK_CHANGED]: (
            prevAudioTrack: string,
            activeAudioTrack: string
        ): string => `Active audio track changed from "${prevAudioTrack}" to "${activeAudioTrack}"`,
        [FeatureMultiTrackAudioMessageId._001_ACTIVE_AUDIO_TRACK_SET]: (activeAudioTrack: string): string =>
            `Active audio track set to "${activeAudioTrack}"`,
    },
    [MessageType.WARNING]: {
        [FeatureMultiTrackAudioMessageId._002_AUDIO_TRACK_NOT_AVAILABLE]: (language: string): string =>
            `Audio track for language "${language}" is not available`,
    },
    [MessageType.ERROR]: {},
};

export type TFeatureMultiTrackAudioMessages = TLoggerMessages<
    MessageNamespace._030_FEATURE_MULTI_TRACK_AUDIO,
    typeof featureMultiTrackAudioMessages
>;

export {featureMultiTrackAudioMessages};
