import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';

import {generateMessageCode} from './generateMessageCode';

const MediaError: typeof window['MediaError'] | null = typeof window !== 'undefined' ? window.MediaError : null;

/**
 * A small wrapper over `Error`, exposing a `code` property allowing
 * for consumer error handling behaviour on a per-error-code
 * (namespace + id) basis.
 *
 * !!-IMPORTANT-!!
 *
 * This class should never be instantiated/called directly from within player
 * business logic unless rejecting a promise.
 *
 * Use `errorManager.throw()`, or `throwPlayerError()` instead as appropriate.
 */

class PlayerError extends Error {
    public args: any[];

    constructor(public namespace: MessageNamespace, public id: number, ...args: any[]) {
        const code = generateMessageCode(namespace, id);

        super(code);

        this.args = args;

        const src: unknown = args[args.length - 1];

        if (src instanceof Error || src instanceof Event || (MediaError && src instanceof MediaError)) {
            // By codebase convention, if an error message's args include a reference to
            // an underlying/native error instance, it should be passed as the _last_
            // parameter.

            console.error(src);
        }
    }
}

export {PlayerError};
