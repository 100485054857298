import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeatureEndingMessageId} from './FeatureEndingMessageId';

interface IPresentationTimelineShiftParams {
    prevPlayheadPositionSeconds: number;
    nextPlayheadPositionSeconds: number;
    presentationTimelineShiftSeconds: number;
}

const featureEndingMessages = {
    [MessageType.LOG]: {
        [FeatureEndingMessageId._000_DYNAMIC_TO_STATIC_TRANSITION_DETECTED]: (): string =>
            'The manifest transitioned from "dynamic" to "static". Initiating ending of the stream.',
        [FeatureEndingMessageId._001_MEDIA_PRESENTATION_DURATION_ADDED]: (): string =>
            'A `MPD@mediaPresentationDuration` attribute was added to a dynamic manifest. Initiating ending ' +
            'of the stream.',
        [FeatureEndingMessageId._002_MINIMUM_UPDATE_PERIOD_REMOVED]: (): string =>
            'The `MPD@minUpdatePeriod` attribute was removed from a dynamic manifest. Initiating ending ' +
            'of the stream.',
        [FeatureEndingMessageId._003_SEEK_RANGE_NOT_INCREMENTED]: (stoppedThresholdDurationMs: number): string =>
            `The seek range did not increment across multiple dynamic manifest updates over a period ` +
            `of ${stoppedThresholdDurationMs}ms. Initiating ending of the stream`,
        [FeatureEndingMessageId._004_PROLONGED_PENDING_UPDATE]: (stoppedThresholdDurationMs: number): string =>
            'The `MPD@publishTime` value did not increment across multiple dynamic manifest updates ' +
            `over a period of ${stoppedThresholdDurationMs}ms. Initiating ending of the stream`,
        [FeatureEndingMessageId._005_PRESENTATION_TIMELINE_SHIFT]: ({
            prevPlayheadPositionSeconds,
            nextPlayheadPositionSeconds,
            presentationTimelineShiftSeconds,
        }: IPresentationTimelineShiftParams): string =>
            `The presentation timeline has shifted by ${presentationTimelineShiftSeconds}s, ` +
            `seeking from ${prevPlayheadPositionSeconds}s to ${nextPlayheadPositionSeconds}s to recover`,
        [FeatureEndingMessageId._007_END_OF_STREAM_MARKER_DETECTED]: (spliceEventId: number): string =>
            `A SCTE35 timeline region with a splice event id of "${spliceEventId}" was detected. ` +
            `Initiating ending of the stream`,
    },
    [MessageType.WARNING]: {
        [FeatureEndingMessageId._006_BUFFERING_AFTER_DYNAMIC_MANIFEST_ENDED_DETECTED]: (
            manifestUrl: string,
            _manifestXml: string
        ): string =>
            'The player entered a buffering state shortly after the dynamic manifest at ' +
            `URL "${manifestUrl}" transitioned to a static state. The manifest XML is attached.`,
    },
    [MessageType.ERROR]: {},
};

export type TFeatureEndingMessages = TLoggerMessages<
    MessageNamespace._023_FEATURE_ENDING,
    typeof featureEndingMessages
>;

export {featureEndingMessages};
