import {MessageType} from '../Constants/MessageType';

const renderHumanReadableMessageTypePrefix = (logType: MessageType): string =>
    ({
        [MessageType.LOG]: '',
        [MessageType.WARNING]: 'WARNING:',
        [MessageType.ERROR]: 'ERROR:',
    }[logType]);

export {renderHumanReadableMessageTypePrefix};
