import type {TEnumerationValue} from '@Shared/Util/enumeration';

// this enum is part of public API so will be removed in 2 steps
// eslint-disable-next-line
enum DebugVerbosityLevel {
    /**
     * Only errors will be output to the console
     */

    ERROR = 1,

    /**
     * Errors and warnings will be output to the console
     */

    WARN = 2,

    /**
     * Errors, warnings and logs will be output to the console (default)
     */

    LOG = 3,
}

// it's used as gradual usage migration for consumers
type TDebugVerbosityLevel = TEnumerationValue<typeof DebugVerbosityLevel>;

export type {TDebugVerbosityLevel};
export {DebugVerbosityLevel};
