import type {State, Store} from '@dazn/depo';
import {BehaviorSubject} from 'rxjs';

import {StoreName} from '../../Constants/StoreName';

import {resolveLocalStorageActions} from './Actions/resolveLocalStorageActions';
import {LocalStorage} from './Models/LocalStorage';

const createLocalStorageStore = (
    state: State,
    initialValue: Partial<LocalStorage> = {}
): Store<LocalStorage, typeof resolveLocalStorageActions, BehaviorSubject<LocalStorage>> => {
    return state.createStore(
        {
            model: LocalStorage,
            name: StoreName.LOCAL_STORAGE,
            actions: resolveLocalStorageActions,
            observable: BehaviorSubject,
        },
        initialValue
    );
};

export {createLocalStorageStore};
