import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import type {KeySessionStatus} from '../Constants/KeySessionStatus';

import type {BufferHealthStatus} from './BufferHealthStatus';
import type {KeySessionStatusMachineEventType} from './KeySessionStatusMachineEventType';
import type {LifecycleStatus} from './LifecycleStatus';
import type {LifecycleStatusMachineEventType} from './LifecycleStatusMachineEventType';
import {MetricsStoreMessageId} from './MetricsStoreMessageId';
import type {PlaybackStatus} from './PlaybackStatus';
import type {PlaybackStatusMachineEventType} from './PlaybackStatusMachineEventType';
import type {PlayheadStatus} from './PlayheadStatus';
import type {StalledStatus} from './StalledStatus';

const metricsStoreMessages = {
    [MessageType.LOG]: {
        [MetricsStoreMessageId._000_PLAYBACK_STATUS_CHANGE]: (
            prevPlaybackStatus: PlaybackStatus,
            nextPlaybackStatus: PlaybackStatus,
            eventType: PlaybackStatusMachineEventType
        ): string =>
            `Playback status changed from "${prevPlaybackStatus}" to "${nextPlaybackStatus}" due ` +
            `to event type "${eventType}"`,
        [MetricsStoreMessageId._001_PLAYHEAD_STATUS_CHANGE]: (
            prevPlayheadStatus: PlayheadStatus,
            nextPlayheadStatus: PlayheadStatus
        ): string => `Playhead status changed from "${prevPlayheadStatus}" to "${nextPlayheadStatus}"`,
        [MetricsStoreMessageId._002_BUFFER_HEALTH_STATUS_CHANGE]: (
            prevBufferHealthStatus: BufferHealthStatus,
            nextBufferHealthStatus: BufferHealthStatus
        ): string => `Buffer health status changed from "${prevBufferHealthStatus}" to "${nextBufferHealthStatus}"`,
        [MetricsStoreMessageId._003_LIFECYCLE_STATUS_CHANGE]: (
            prevLifecycleStatus: LifecycleStatus,
            nextLifecycleStatus: LifecycleStatus,
            eventType: LifecycleStatusMachineEventType
        ): string =>
            `Lifecycle status changed from "${prevLifecycleStatus}" to "${nextLifecycleStatus}" due ` +
            `to event type "${eventType}"`,
        [MetricsStoreMessageId._004_STALLED]: (
            prevStalledStatus: StalledStatus,
            nextStalledStatus: StalledStatus
        ): string =>
            'A non-advancing current time lead to a change of stall status from ' +
            `"${prevStalledStatus}" to "${nextStalledStatus}"`,
        [MetricsStoreMessageId._005_KEY_SESSION_STATUS_CHANGE]: (
            prevKeySessionStatus: KeySessionStatus,
            nextKeySessionStatus: KeySessionStatus,
            eventType: KeySessionStatusMachineEventType
        ): string =>
            `Key session status changed from "${prevKeySessionStatus}" to "${nextKeySessionStatus}" due ` +
            `to event type "${eventType}"`,
        [MetricsStoreMessageId._006_BUFFERING_RANGE_DROPPED]: (
            url: string,
            periodId: string,
            language: string
        ): string =>
            `Segment "${url}" for period "${periodId}" and language "${language}" dropped from buffering range` +
            ` due to detected precence of existing segment`,
        [MetricsStoreMessageId._007_SEGMENT_IGNORED_BUFFERING_DEPRECATED]: (
            url: string,
            periodId: string,
            language: string
        ): string =>
            `Segment "${url}" (for period "${periodId}" and language "${language}") not added to buffering range.` +
            ` Segment is already buffering.`,
        [MetricsStoreMessageId._007_SEGMENT_IGNORED_BUFFERING_DEPRECATED]: (
            url: string,
            periodId: string,
            language: string
        ): string =>
            `Segment "${url}" (for period "${periodId}" and language "${language}") not added to buffering range.` +
            ` Segment is already buffered.`,
    },
    [MessageType.WARNING]: {
        [MetricsStoreMessageId._009_SEGMENT_IGNORED_BUFFERING]: (
            id: string,
            url: string,
            periodId: string,
            language: string,
            variant: number
        ): string =>
            `Segment:${id}  (url: "${url}", period: "${periodId}", language: "${language}", variant: "${variant}")` +
            ` not added to buffering range. Segment is already buffering.`,
        [MetricsStoreMessageId._010_SEGMENT_IGNORED_BUFFERED]: (
            id: string,
            url: string,
            periodId: string,
            language: string,
            variant: number
        ): string =>
            `Segment:${id}  (url: "${url}", period: "${periodId}", language: "${language}", variant: "${variant}")` +
            ` not added to buffering range. Segment is already buffered.`,
    },
    [MessageType.ERROR]: {},
};

export type TMetricsStoreMessages = TLoggerMessages<MessageNamespace._026_METRICS_STORE, typeof metricsStoreMessages>;

export {metricsStoreMessages};
