import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {HlsManifestParserMessageId} from './hlsManifestParserMessageId';

const hlsManifestParserMessages = {
    [MessageType.LOG]: {
        [HlsManifestParserMessageId._000_UNHANDLED_EXCEPTION]: (err: unknown): string =>
            `Unhandled exception: ${err instanceof Error ? err.message : err}`,
    },
    [MessageType.WARNING]: {},
    [MessageType.ERROR]: {},
};

export type THlsManifestParserMessages = TLoggerMessages<
    MessageNamespace._010_HLS_MANIFEST_PARSER,
    typeof hlsManifestParserMessages
>;

export {hlsManifestParserMessages};
