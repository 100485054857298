import type {Segment} from '@Shared/Models/Segment';
import {hasElements} from '@Shared/Util/hasElements';

/**
 * This function can be called often and segment lists can be very long. To avoid
 * O(n) iteration, we take a pointer-based approach instead where the "normal"
 * duration is the first duration where the left and right pointers yield the same
 * value. Not completely accurate, but should all most all real-world scenarios
 * efficiently.
 */

const deriveNormalSegmentDurationFromSegmentList = (
    segments: Segment[],
    left = 0,
    right = segments.length - 1
): number => {
    // we access indices between 0 and segments.length - 1 so we don't need to check if elements exist
    if (hasElements(segments) && left < right && segments[left]!.durationSeconds !== segments[right]!.durationSeconds) {
        return deriveNormalSegmentDurationFromSegmentList(segments, ++left, --right);
    }

    return segments[left]?.durationSeconds ?? -1;
};

export {deriveNormalSegmentDurationFromSegmentList};
