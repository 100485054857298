import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeatureMultiPeriodDashMessageId} from './FeatureMultiPeriodDashMessageId';

const featureMultiPeriodDashMessages = {
    [MessageType.LOG]: {
        [FeatureMultiPeriodDashMessageId._000_ACTIVE_PERIOD_CHANGED]: (
            activePeriodId: string,
            activePeriodLanguages: string[]
        ): string =>
            `Active period changed to "${activePeriodId}" with languages ${JSON.stringify(activePeriodLanguages)}`,
    },
    [MessageType.WARNING]: {},
    [MessageType.ERROR]: {},
};

export type TFeatureMultiPeriodDashMessages = TLoggerMessages<
    MessageNamespace._008_FEATURE_MULTI_PERIOD_DASH,
    typeof featureMultiPeriodDashMessages
>;

export {featureMultiPeriodDashMessages};
