import {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {logger} from '@Logger/logger';
import type {Manifest} from '@Shared/Models/Manifest';

import {FeatureEndingMessageId} from '../Constants/FeatureEndingMessageId';

/**
 * Diffs the current and incoming manifests, to check for signs that the live
 * manifest has ended. This function deals with non-ambiguous changes that can be
 * statically detected over one manifest update.
 *
 * NB: For more ambiguous changes that occur over a longer time period, a different
 * process is used.
 *
 * @see: ../../../../../docs/detection-of-live-stream-end.md
 */

const hasLiveStreamEnded = (prevManifest: Manifest, nextManifest: Manifest): boolean => {
    // 1. The manifest changed from live to vod

    if (prevManifest.isDynamic && nextManifest.isStatic) {
        logger.log(
            MessageNamespace._023_FEATURE_ENDING,
            FeatureEndingMessageId._000_DYNAMIC_TO_STATIC_TRANSITION_DETECTED
        );

        return true;
    }

    // 2. A `MPD@mediaPresentationDuration` attribute has appeared

    if (prevManifest.mediaPresentationDurationSeconds <= 0 && nextManifest.mediaPresentationDurationSeconds > 0) {
        logger.log(MessageNamespace._023_FEATURE_ENDING, FeatureEndingMessageId._001_MEDIA_PRESENTATION_DURATION_ADDED);

        return true;
    }

    // 3. The `MPD@minUpdatePeriod` attribute has been removed

    if (prevManifest.minimumUpdatePeriodSeconds > 0 && nextManifest.minimumUpdatePeriodSeconds === -1) {
        logger.log(MessageNamespace._023_FEATURE_ENDING, FeatureEndingMessageId._002_MINIMUM_UPDATE_PERIOD_REMOVED);

        return true;
    }

    return false;
};

export {hasLiveStreamEnded};
