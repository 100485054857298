import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import type {PlaybackStatusMachineEventType} from '@State/Stores/Metrics/Constants/PlaybackStatusMachineEventType';

import {LifecycleManagerMessageId} from './LifecycleManagerMessageId';

const lifecycleManagerMessages = {
    [MessageType.LOG]: {
        [LifecycleManagerMessageId._000_SOURCE_BUFFERS_DETACHED]: (): string =>
            'Audio and video source buffers released',
        [LifecycleManagerMessageId._001_MESSAGE_ID_NOT_CURRENTLY_IN_USE]: (): string => '',
        [LifecycleManagerMessageId._002_KEY_SESSIONS_CLOSED]: (): string => 'DRM key sessions closed',
        [LifecycleManagerMessageId._003_MSE_RESOURCES_RELEASED]: (): string => 'MSE resources released',
        [LifecycleManagerMessageId._004_PLAYER_UNLOADED]: (event: PlaybackStatusMachineEventType): string =>
            `Tear down complete. Player unloaded due to "${event}".`,
        [LifecycleManagerMessageId._005_PLAYER_DESTROYED]: (): string => 'Tear down complete. Player destroyed.',
        [LifecycleManagerMessageId._006_CDM_CLOSED]: (): string => 'DRM CDM Closed',
    },
    [MessageType.WARNING]: {
        [LifecycleManagerMessageId._007_TEAR_DOWN_EXCEPTION]: (messageOrException: unknown): string =>
            `An exception occurred during tear down: ${messageOrException}`,
    },
    [MessageType.ERROR]: {},
};

export type TLifecycleManagerMessages = TLoggerMessages<
    MessageNamespace._021_LIFECYCLE_MANAGER,
    typeof lifecycleManagerMessages
>;

export {lifecycleManagerMessages};
