import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import type {Period} from '@Shared/Models/Period';

import {FeatureCodecFilteringMessageId} from './FeatureCodecFilteringMessageId';

const featureCodecFilteringMessages = {
    [MessageType.LOG]: {
        [FeatureCodecFilteringMessageId._003_ALL_CODECS_SUPPORTED]: (): string =>
            'All codecs found in manifest are supported',
    },
    [MessageType.WARNING]: {
        [FeatureCodecFilteringMessageId._000_UNSUPPORTED_AUDIO_CODEC]: (
            audioCodec: string,
            periodId: string,
            variantIndex: number
        ): string =>
            `Unsupported audio codec: "${audioCodec}" in period ${periodId}, variant ${variantIndex}. ` +
            'Filtering out variant.',
        [FeatureCodecFilteringMessageId._001_UNSUPPORTED_VIDEO_CODEC]: (
            videoCodec: string,
            periodId: string,
            variantIndex: number
        ): string =>
            `Unsupported video codec: "${videoCodec}" in period ${periodId}, variant ${variantIndex}. ` +
            'Filtering out variant.',
    },
    [MessageType.ERROR]: {
        [FeatureCodecFilteringMessageId._002_NO_PLAYABLE_VARIANTS]: (period: Period): string =>
            `No playable variants in period "${period.id}" due to unsupported codecs`,
    },
};

export type TFeatureCodecFilteringMessages = TLoggerMessages<
    MessageNamespace._020_FEATURE_CODEC_FILTERING,
    typeof featureCodecFilteringMessages
>;

export {featureCodecFilteringMessages};
