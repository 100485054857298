import type {BufferHealthStatus} from '../Constants/BufferHealthStatus';

import {getBufferHealthStatusPrecedence} from './getBufferHealthStatusPrecedence';

const hasBufferHealthStatusDeteriorated = (
    prevBufferHealthStatus: BufferHealthStatus,
    nextBufferHealthStatus: BufferHealthStatus
): boolean => {
    if (prevBufferHealthStatus === nextBufferHealthStatus) return false;

    const prevPrecedence = getBufferHealthStatusPrecedence(prevBufferHealthStatus);
    const nextPrecedence = getBufferHealthStatusPrecedence(nextBufferHealthStatus);

    return nextPrecedence < prevPrecedence;
};

export {hasBufferHealthStatusDeteriorated};
