import {KeySessionStatus} from '../Constants/KeySessionStatus';
import {KeySessionStatusMachineEventType} from '../Constants/KeySessionStatusMachineEventType';

const keySessionStatusMachine = (
    prevKeySessionStatus: KeySessionStatus,
    eventType: KeySessionStatusMachineEventType
): KeySessionStatus => {
    switch (prevKeySessionStatus) {
        case KeySessionStatus.ACTIVE:
            switch (eventType) {
                case KeySessionStatusMachineEventType.KEY_SESSION_CLOSED:
                    return KeySessionStatus.INACTIVE;
                default:
                    return prevKeySessionStatus;
            }

        case KeySessionStatus.INACTIVE:
            switch (eventType) {
                case KeySessionStatusMachineEventType.KEY_SESSION_REQUESTED:
                    return KeySessionStatus.PENDING;
                default:
                    return prevKeySessionStatus;
            }

        case KeySessionStatus.PENDING:
            switch (eventType) {
                case KeySessionStatusMachineEventType.KEY_SESSION_ACTIVE:
                case KeySessionStatusMachineEventType.PROGRESS:
                    return KeySessionStatus.ACTIVE;
                default:
                    return prevKeySessionStatus;
            }

        default:
            return prevKeySessionStatus;
    }
};

export {keySessionStatusMachine};
